import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import {
    Typography,
    Grid,
    Button,
    Box,
    Modal,
    Paper,
    Card,
    CardContent,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tooltip,
    Snackbar,
    Avatar,
    Switch, FormControlLabel,
    CircularProgress,
    Alert
} from '@mui/material';
import { collection, getDocs, doc, updateDoc, writeBatch, increment, getDoc, deleteDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { RouteGroup, Route, Client, Order, InventoryItem } from '../models';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon, Check as CheckIcon } from '@mui/icons-material';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import OrderCreationModal from './OrderCreationModal';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { keyframes } from '@mui/system';
import { optimizeWaypoints } from '../utils/routeOptimization'; // You'll need to create this utility function
import { LocalShipping, Store, PendingActions, CheckCircle, Cancel } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MapIcon from '@mui/icons-material/DirectionsCar';
import WazeIcon from '@mui/icons-material/Navigation'; // Usamos DirectionsCar como sustituto de Waze
import { styled } from '@mui/system';
import "react-datepicker/dist/react-datepicker.css";
import { CalendarToday, Repeat, Today } from '@mui/icons-material';
import { FormatListBulleted } from '@mui/icons-material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { motion, AnimatePresence } from 'framer-motion';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Importar el icono del calendario
import { MapContainer, TileLayer, Marker, Popup, Circle, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';

// Ensure marker icons are displayed correctly
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


const MarkerIcon: React.FC<{ number: string }> = ({ number }) => {
    return (
        <div style={{
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
            lineHeight: '20px', // Ajusta según el tamaño del círculo
        }}>
            {number}
        </div>
    );
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        overflow: 'hidden',
    },
}));

const DialogTitleWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#87CEEB',
    color: 'white',
    padding: theme.spacing(2),
    position: 'relative',
}));

const DateCircle = styled(Box)(({ theme }) => ({
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    backgroundColor: 'white',
    color: '#87CEEB',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#00b4d8',
        },
        '&:hover fieldset': {
            borderColor: '#0095b6',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00b4d8',
        },
    },
    '& .MuiInputLabel-root': {
        color: '#00b4d8',
    },
}));

// Componente separado para el input del nombre
const NameInput = React.memo(({ initialName, onNameChange }: { initialName: string, onNameChange: (name: string) => void }) => {
    const [localName, setLocalName] = useState(initialName);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalName(e.target.value);
    };

    const handleBlur = () => {
        onNameChange(localName);
    };

    return (
        <StyledFormControl fullWidth>
            <TextField
                fullWidth
                label="Nombre"
                value={localName}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        </StyledFormControl>
    );
});


const RouteTable = styled(Box)(({ theme }) => ({
    width: '100%',
    overflowX: 'auto',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    padding: theme.spacing(2),
}));

const RouteRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '4px',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    '&:hover': {
        boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    },
}));

const OrderNumber = styled(Box)(({ theme }) => ({
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#FFA500',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    color: 'white',
    fontWeight: 'bold',
}));

const RouteTableHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '4px 4px 0 0',
    padding: theme.spacing(2),
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
}));

const HeaderCell = styled(Box)(({ theme }) => ({
    flexGrow: 0,
    flexShrink: 0,
    marginRight: theme.spacing(2),
}));

const columnNames: Record<string, string> = {
    cant_gato: 'Gatos',
    cant_perro_mini: 'Perros Mini',
    cant_perro_pequeno: 'Perros Pequeños',
    cant_perro_mediano: 'Perros Medianos',
    cant_perro_grande: 'Perros Grandes',
    cant_perro_gigante: 'Perros Gigantes'
};

// Definir los iconos personalizados
const OllinIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
        <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2M1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2" />
    </SvgIcon>
);

const RecoleccionIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 512 512">
        <path d="M184.561 261.903c3.232 13.997-12.123 24.635-24.068 17.168l-40.736-25.455-50.867 81.402C55.606 356.273 70.96 384 96.012 384H148c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12H96.115c-75.334 0-121.302-83.048-81.408-146.88l50.822-81.388-40.725-25.448c-12.081-7.547-8.966-25.961 4.879-29.158l110.237-25.45c8.611-1.988 17.201 3.381 19.189 11.99l25.452 110.237zm98.561-182.915l41.289 66.076-40.74 25.457c-12.051 7.528-9 25.953 4.879 29.158l110.237 25.45c8.672 1.999 17.215-3.438 19.189-11.99l25.45-110.237c3.197-13.844-11.99-24.719-24.068-17.168l-40.687 25.424-41.263-66.082c-37.521-60.033-125.209-60.171-162.816 0l-17.963 28.766c-3.51 5.62-1.8 13.021 3.82 16.533l33.919 21.195c5.62 3.512 13.024 1.803 16.536-3.817l17.961-28.743c12.712-20.341 41.973-19.676 54.257-.022zM497.288 301.12l-27.515-44.065c-3.511-5.623-10.916-7.334-16.538-3.821l-33.861 21.159c-5.62 3.512-7.33 10.915-3.818 16.536l27.564 44.112c13.257 21.211-2.057 48.96-27.136 48.96H320V336.02c0-14.213-17.242-21.383-27.313-11.313l-80 79.981c-6.249 6.248-6.249 16.379 0 22.627l80 79.989C302.689 517.308 320 510.3 320 495.989V448h95.88c75.274 0 121.335-82.997 81.408-146.88z" />
    </SvgIcon>
);

const CalendarContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
    maxWidth: '1200px',
    margin: '0 auto',
}));

const MonthNavigation = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
}));

const MonthNavigationDialog = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const NavButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#00b4d8',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: '10px 20px',
    '&:hover': {
        backgroundColor: '#0095b6',
    },
}));

const DayCell = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'isCurrentDay' && prop !== 'hasRoutes' && prop !== 'isWeekend' && prop !== 'isCurrentMonth',
})<{ isCurrentDay?: boolean; hasRoutes: boolean; isWeekend: boolean; isCurrentMonth: boolean }>(
    ({ theme, isCurrentDay, hasRoutes, isWeekend, isCurrentMonth }) => ({
        padding: theme.spacing(1),
        margin: theme.spacing(0.5),
        textAlign: 'left',
        height: '120px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        backgroundColor: isCurrentDay
            ? '#e1f5fe'
            : isCurrentMonth
                ? '#ffffff'
                : '#f0f0f0',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        opacity: isCurrentMonth ? 1 : 0.5,
        overflow: 'hidden',
        transition: 'all 0.3s ease',
    })
);

const DayNumber = styled(Typography)<{ isCurrentDay?: boolean }>(({ theme, isCurrentDay }) => ({
    fontWeight: 'bold',
    fontSize: '1rem',
    color: isCurrentDay ? '#00b4d8' : 'inherit',
    marginBottom: theme.spacing(1),
}));

const RouteList = styled(Box)(({ theme }) => ({
    overflowY: 'auto',
    flexGrow: 1,
}));

const RouteItem = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    backgroundColor: 'rgba(0, 180, 216, 0.1)',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: 'rgba(0, 180, 216, 0.2)',
    },
}));

const WeekdayLabel = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: '#333',
    fontSize: '0.9rem',
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
}));

const MonthYearLabel = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '1.5rem',
    color: '#00b4d8',
}));

const ModalContent = styled(Paper)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '1200px',
    maxHeight: '90vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 4px 10px rgba(0,0,0,0.1)', // Replace theme.shadows[5] with a specific shadow
    padding: theme.spacing(4),
    borderRadius: '12px',
}));


const RouteCardsContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    minHeight: '300px',
    overflowY: 'auto',
    maxHeight: '500px', // Ajusta este valor según tus necesidades
}));

const RouteCard = styled(Card, {
    shouldForwardProp: (prop) => prop !== 'isFirst',
})<{ isFirst?: boolean }>(({ theme, isFirst }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    transition: 'all 0.3s ease',
    '&:hover': {
        boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
    },
    cursor: 'grab',
    backgroundColor: isFirst ? '#00b4d8' : 'white',
    color: isFirst ? 'white' : 'inherit',
    border: isFirst ? '3px solid #1e88e5' : '1px solid #e0e0e0',
}));

const RouteCardContent = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));


// const mapContainerStyle = {
//     width: '100%',
//     height: '500px'
// };

// const CustomInfoWindow = styled(InfoWindow)(({ theme }) => ({
//     '& .gm-style-iw': {
//         backgroundColor: 'white',
//         borderRadius: '8px',
//         padding: '12px',
//         maxWidth: '250px',
//     },
//     '& .gm-style-iw-d': {
//         overflow: 'hidden !important',
//     },
//     '& .gm-ui-hover-effect': {
//         backgroundColor: '#FFA500 !important',
//         opacity: '1 !important',
//         padding: '5px !important',
//         right: '5px !important',
//         top: '5px !important',
//         '& img': {
//             width: '14px !important',
//             height: '14px !important',
//         },
//     },
// }));

const circleAnimation = keyframes`
  0% {
    stroke-dasharray: 0, 100;
  }
  100% {
    stroke-dasharray: 100, 100;
  }
`;

const SuccessModal = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: '12px',
        padding: theme.spacing(3),
        textAlign: 'center',
    },
}));

const AnimatedCheckIcon = styled('svg')({
    width: '60px',
    height: '60px',
});

const AnimatedCircle = styled('circle')({
    fill: 'none',
    stroke: 'green',
    strokeWidth: '3',
    strokeLinecap: 'round',
    animation: `${circleAnimation} 2s ease-in-out forwards`,
});

const OrdersCalendar: React.FC = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [routeGroups, setRouteGroups] = useState<RouteGroup[]>([]);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [currentRouteGroup, setCurrentRouteGroup] = useState<RouteGroup | null>(null);
    const [clients, setClients] = useState<Client[]>([]);
    const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
    const [currentRoute, setCurrentRoute] = useState<Route | null>(null);
    const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
    const [routePoints, setRoutePoints] = useState<google.maps.LatLngLiteral[]>([]);
    const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null);
    const [selectedMarker, setSelectedMarker] = useState<number | null>(null);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [availableClients, setAvailableClients] = useState<Client[]>([]);
    const [hoveredMarker, setHoveredMarker] = useState<number | null>(null);
    const [isRouteUpdated, setIsRouteUpdated] = useState(false);
    const [viewDialogOpen, setViewDialogOpen] = useState(false);
    const [selectedRoute, setSelectedRoute] = useState<Route | null>(null);
    const [calendarDialogOpen, setCalendarDialogOpen] = useState(false);
    const [selectedRouteGroup, setSelectedRouteGroup] = useState<RouteGroup | null>(null);

    const [localRouteName, setLocalRouteName] = useState('');
    const [currentRouteIndex, setCurrentRouteIndex] = useState<number>(0);

    // Definición del estado para el centro del mapa
    const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({ lat: 9.9281, lng: -84.0907 }); // Ajusta las coordenadas según sea necesario

    // Asegúrate de que mapRef esté correctamente tipado
    const mapRef = useRef<L.Map | null>(null);

    // Define un nuevo estado para las coordenadas decodificadas
    const [decodedRouteCoordinates, setDecodedRouteCoordinates] = useState<[number, number][]>([]);


    useEffect(() => {
        fetchRouteGroups();
        fetchClients();
        fetchInventoryItems();
    }, [currentDate]);

    useEffect(() => {
        if (isRouteUpdated && currentRouteGroup) {
            const points = currentRouteGroup.routes.map(route => {
                const client = clients.find(c => c.id === route.clientId);
                if (client && client.googleMapsLink) {
                    return extractCoordinates(client.googleMapsLink);
                }
                return null;
            }).filter((point): point is google.maps.LatLngLiteral => point !== null);

            calculateOptimalRoute(points, currentRouteGroup.routes).then(() => {
                setIsRouteUpdated(false);
            });
        }
    }, [isRouteUpdated, currentRouteGroup, clients]);

    // Asegúrate de que esta función se llame cada vez que cambies las rutas
    const updateAvailableClients = useCallback(() => {
        if (currentRouteGroup) {
            const usedClientIds = new Set(currentRouteGroup.routes.map(route => route.clientId).filter(id => id !== ''));
            const available = clients.filter(client => client.id && !usedClientIds.has(client.id));
            setAvailableClients(available);
        } else {
            setAvailableClients(clients);
        }
    }, [currentRouteGroup, clients]);

    useEffect(() => {
        updateAvailableClients();
    }, [currentRouteGroup, updateAvailableClients]);

    const fetchRouteGroups = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'routeGroups'));
            const groups: RouteGroup[] = [];
            for (const docRef of querySnapshot.docs) {
                const groupData = docRef.data();
                const routesSnapshot = await getDocs(collection(db, 'routeGroups', docRef.id, 'routes'));
                const routes: Route[] = routesSnapshot.docs.map(routeDoc => ({
                    id: routeDoc.id,
                    ...routeDoc.data()
                } as Route));

                // Si necesitas ordenar las rutas, usa un campo que esté seguro que existe
                // Por ejemplo, si tienes un campo 'order' o 'sequence', úsalo en lugar de orderIndex
                routes.sort((a, b) => {
                    const orderA = a.orderIndex ?? 0;
                    const orderB = b.orderIndex ?? 0;
                    return orderA - orderB;
                });

                groups.push({
                    id: docRef.id,
                    name: groupData.name,
                    dayOfWeek: groupData.dayOfWeek,
                    weekOfMonth: groupData.weekOfMonth,
                    createdAt: groupData.createdAt.toDate(),
                    routes: routes,
                    routeType: groupData.routeType || 'monthly'
                });
            }
            setRouteGroups(groups);
        } catch (error) {
            console.error("Error fetching route groups:", error);
            toast.error('Error al cargar los grupos de rutas');
        }
    };

    const fetchClients = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'clients'));
            setClients(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Client)));
        } catch (error) {
            console.error("Error fetching clients:", error);
            toast.error('Error al cargar los clientes');
        }
    };

    const fetchInventoryItems = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'inventoryItems'));
            setInventoryItems(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as InventoryItem)));
        } catch (error) {
            console.error("Error fetching inventory items:", error);
            toast.error('Error al cargar los items de inventario');
        }
    };

    const getDaysInMonth = (date: Date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDayOfMonth = new Date(year, month, 1);
        const lastDayOfMonth = new Date(year, month + 1, 0);

        const firstDayOfWeek = (firstDayOfMonth.getDay() + 6) % 7;
        const daysToIncludeFromPreviousMonth = firstDayOfWeek;
        const daysToIncludeFromNextMonth = (7 - lastDayOfMonth.getDay() + 6) % 7;

        const startDate = new Date(firstDayOfMonth);
        startDate.setDate(startDate.getDate() - daysToIncludeFromPreviousMonth);

        const endDate = new Date(lastDayOfMonth);
        endDate.setDate(endDate.getDate() + daysToIncludeFromNextMonth);

        const days = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            days.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return days;
    };

    const isCurrentMonth = (date: Date) => {
        return date.getMonth() === currentDate.getMonth() && date.getFullYear() === currentDate.getFullYear();
    };

    const renderDayCells = () => {
        const days = getDaysInMonth(currentDate);
        const totalCells = 42;
        const weeks: React.ReactNode[] = [];
        let week: React.ReactNode[] = [];

        for (let i = 0; i < totalCells; i++) {
            const date = i < days.length ? days[i] : null;
            const routes = date ? getRoutesForDay(date) : [];
            const isWeekend = date ? (date.getDay() === 0 || date.getDay() === 6) : false;

            week.push(
                <Grid item xs key={i}>
                    <DayCell
                        isCurrentDay={date ? isCurrentDay(date) : false}
                        hasRoutes={routes.length > 0}
                        isWeekend={isWeekend}
                        isCurrentMonth={date ? isCurrentMonth(date) : false}
                    >
                        {date && (
                            <>
                                <DayNumber isCurrentDay={isCurrentDay(date)}>{date.getDate()}</DayNumber>
                                <RouteList>
                                    {routes.map((route) => (
                                        <RouteItem
                                            key={route.id}
                                            onClick={() => handleEditRoute(route)}
                                        >
                                            <Typography variant="caption" noWrap>
                                                {route.name}
                                            </Typography>
                                        </RouteItem>
                                    ))}
                                </RouteList>
                            </>
                        )}
                    </DayCell>
                </Grid>
            );

            if (week.length === 7 || i === totalCells - 1) {
                weeks.push(
                    <Grid container spacing={1} key={`week-${weeks.length}`}>
                        {week}
                    </Grid>
                );
                week = [];
            }
        }

        return weeks;
    };

    const getRoutesForDay = (date: Date | null) => {
        if (!date) return [];

        return routeGroups.filter((group) => {
            const createdAt = new Date(group.createdAt);
            createdAt.setHours(0, 0, 0, 0);
            const checkDate = new Date(date);
            checkDate.setHours(0, 0, 0, 0);

            const timeDifference = checkDate.getTime() - createdAt.getTime();
            const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
            const weekOfMonth = Math.ceil((checkDate.getDate() - 1) / 7);

            switch (group.routeType) {
                case 'weekly':
                    return daysDifference >= 0 && daysDifference % 7 === 0;
                case 'biweekly':
                    return daysDifference >= 0 && daysDifference % 14 === 0;
                case 'monthly':
                    if (group.dayOfWeek !== ((checkDate.getDay() + 6) % 7 + 1)) return false;
                    return weekOfMonth === group.weekOfMonth;
                case 'bimonthly':
                    if (group.dayOfWeek !== ((checkDate.getDay() + 6) % 7 + 1)) return false;
                    if (weekOfMonth !== group.weekOfMonth) return false;
                    const monthDifference = (checkDate.getFullYear() - createdAt.getFullYear()) * 12 + checkDate.getMonth() - createdAt.getMonth();
                    return monthDifference % 2 === 0;
                default:
                    return false;
            }
        });
    };

    const handlePrevMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };

    const isCurrentDay = (date: Date | null) => {
        if (!date) return false;
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    // Función para decodificar la geometría de Polyline
    const decodePolyline = (polyline: string): [number, number][] => {
        const coordinates: [number, number][] = [];
        let index = 0, len = polyline.length;
        let lat = 0, lng = 0;

        while (index < len) {
            let b, shift = 0, result = 0;
            do {
                b = polyline.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            const dlat = ((result >> 1) ^ -(result & 1));
            lat += dlat;

            shift = 0;
            result = 0;
            do {
                b = polyline.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            const dlng = ((result >> 1) ^ -(result & 1));
            lng += dlng;

            coordinates.push([lng * 1e-5, lat * 1e-5]); // Convertir a coordenadas
        }

        return coordinates;
    };

    const calculateOptimalRoute = async (points: { lat: number; lng: number }[], updatedRoutes: Route[]) => {
        if (points.length < 2) {
            setDirections(null);
            return;
        }

        const coordinates = points.map(point => `${point.lng},${point.lat}`).join(';');
        // const osrmUrl = `http://router.project-osrm.org/route/v1/driving/${coordinates}?overview=full`;
        const osrmUrl = `https://router.project-osrm.org/route/v1/driving/${coordinates}?overview=full`;

        try {
            const response = await axios.get(osrmUrl);
            console.log(response.data); // Verifica la estructura aquí
            const route = response.data.routes[0];


            // Decodificar la geometría de Polyline
            const decodedCoordinates = decodePolyline(route.geometry);
            console.log(decodedCoordinates); // Verifica que contenga las coordenadas
            setDecodedRouteCoordinates(decodedCoordinates); // Almacenar las coordenadas decodificadas
            setDirections(response.data); // Almacenar la respuesta completa



            // // Verifica que la respuesta tenga rutas
            // if (response.data.routes && response.data.routes.length > 0) {
            //     const route = response.data.routes[0];

            //     // Decodificar la geometría de Polyline
            //     const decodedCoordinates = decodePolyline(route.geometry);
            //     setDecodedRouteCoordinates(decodedCoordinates); // Almacenar las coordenadas decodificadas
            //     setDirections(response.data); // Almacenar la respuesta completa
            // } else {
            //     console.error("No se encontraron rutas en la respuesta de la API.");
            //     setDirections(null); // O maneja el estado como prefieras
            // }




            // if (route) {
            //     setDirections(route.geometry); // Aquí puedes almacenar la geometría de la ruta

            //     // Actualizar el orden de las rutas en el estado
            //     if (currentRouteGroup) {
            //         const waypointOrder: number[] = route.waypoint_order; // Asegúrate de que waypointOrder sea un arreglo de números
            //         const reorderedRoutes = [
            //             updatedRoutes[0],
            //             ...waypointOrder.map((index: number) => updatedRoutes[index + 1]),
            //             updatedRoutes[updatedRoutes.length - 1]
            //         ];
            //         setCurrentRouteGroup({
            //             ...currentRouteGroup,
            //             routes: reorderedRoutes
            //         });

            //         // Reordenar los puntos de latitud y longitud
            //         const reorderedPoints = [
            //             points[0],
            //             ...waypointOrder.map((index: number) => points[index + 1]),
            //             points[points.length - 1]
            //         ];
            //         setRoutePoints(reorderedPoints);
            //     }

            //     // Ajustar el zoom y el centro del mapa
            //     if (mapRef.current && points.length > 1) {
            //         const bounds = new L.LatLngBounds(points.map(point => [point.lat, point.lng] as [number, number])); // Cambia a un arreglo de tuplas
            //         mapRef.current.fitBounds(bounds);
            //     }
            // } else {
            //     throw new Error("No se pudo encontrar una ruta.");
            // }
        } catch (error) {
            console.error(error);
            toast.error('Error al calcular la ruta óptima');
        }
    };

    const handleRouteChange = (index: number, field: keyof Route, value: any) => {
        if (!currentRouteGroup || availableClients.length === 0) return;
        const updatedRoutes = [...currentRouteGroup.routes];
        updatedRoutes[index] = { ...updatedRoutes[index], [field]: value };
        setCurrentRouteGroup({ ...currentRouteGroup, routes: updatedRoutes });
        if (field === 'clientId') {
            updateRoutePoints(updatedRoutes);
        }
        updateAvailableClients();
        setIsRouteUpdated(true);
    };

    const updateRoutePoints = useCallback(async (routes: Route[]) => {
        const points: { lat: number; lng: number }[] = []; // Cambiar el tipo a un objeto simple
        for (const route of routes) {
            const client = clients.find(c => c.id === route.clientId);
            if (client && client.googleMapsLink) {
                const coordinates = extractCoordinates(client.googleMapsLink);
                if (coordinates) {
                    points.push(coordinates);
                }
            }
        }
        setRoutePoints(points);
        if (points.length > 0) {
            await calculateOptimalRoute(points, routes);
        } else {
            setDirections(null);
        }
    }, [clients]);

    const handleDragEnd = useCallback(async (result: DropResult) => {
        if (!result.destination || !currentRouteGroup) return;

        const { source, destination } = result;

        if (source.index === destination.index) return;

        // Crear una nueva lista de rutas
        const newRoutes = Array.from(currentRouteGroup.routes);
        const [reorderedItem] = newRoutes.splice(source.index, 1);
        newRoutes.splice(destination.index, 0, reorderedItem);

        // Actualizar currentRouteGroup con las nuevas rutas
        setCurrentRouteGroup(prevGroup => ({
            ...prevGroup!,
            routes: newRoutes
        }));

        // Actualizar el índice del marcador seleccionado y el marcador sobre el que se pasa el ratón
        setSelectedMarker(null);
        setHoveredMarker(null);

        setIsRouteUpdated(true);
    }, [currentRouteGroup]);

    const handleEditRoute = useCallback((routeGroup: RouteGroup) => {
        setCurrentRouteGroup(routeGroup);
        setLocalRouteName(routeGroup.name);
        setEditDialogOpen(true);

        const points = routeGroup.routes.map(route => {
            const client = clients.find(c => c.id === route.clientId);
            if (client && client.googleMapsLink) {
                return extractCoordinates(client.googleMapsLink);
            }
            return null;
        }).filter((point): point is google.maps.LatLngLiteral => point !== null);

        updateRoutePoints(routeGroup.routes);
        calculateOptimalRoute(points, routeGroup.routes);
    }, [updateRoutePoints, calculateOptimalRoute, clients]);

    const handleNameChange = useCallback((newName: string) => {
        if (currentRouteGroup) {
            setCurrentRouteGroup(prevGroup => ({
                ...prevGroup!,
                name: newName
            }));
        }
    }, [currentRouteGroup]);

    const handleCloseEditDialog = () => {
        setEditDialogOpen(false);
        setCurrentRouteGroup(null);
        setDirections(null);
        setRoutePoints([]);
    };


    const handleSaveRouteGroup = async () => {
        if (!currentRouteGroup || !currentRouteGroup.id) {
            console.error("No hay un grupo de rutas válido para guardar");
            toast.error('Error al guardar el grupo de rutas');
            return;
        }

        try {
            const batch = writeBatch(db);
            const routeGroupRef = doc(db, 'routeGroups', currentRouteGroup.id);

            batch.update(routeGroupRef, {
                name: currentRouteGroup.name,
                dayOfWeek: currentRouteGroup.dayOfWeek,
                weekOfMonth: currentRouteGroup.weekOfMonth,
                routeType: currentRouteGroup.routeType,
                createdAt: currentRouteGroup.createdAt
            });

            const routesRef = collection(routeGroupRef, 'routes');
            const existingRoutesSnapshot = await getDocs(routesRef);

            existingRoutesSnapshot.docs.forEach((doc) => {
                batch.delete(doc.ref);
            });

            currentRouteGroup.routes.forEach((route, index) => {
                const newRouteRef = doc(routesRef);
                batch.set(newRouteRef, {
                    clientId: route.clientId,
                    status: route.status,
                    order: route.order,
                    orderIndex: index
                });
            });

            await batch.commit();
            setSuccessModalOpen(true);
            setTimeout(() => {
                setSuccessModalOpen(false);
                handleCloseEditDialog();
                fetchRouteGroups();
            }, 2000);
        } catch (error) {
            console.error("Error saving route group: ", error);
            toast.error('Error al guardar el grupo de rutas');
        }
    };

    const extractCoordinates = (url: string): google.maps.LatLngLiteral | null => {
        const regex = /query=(-?\d+\.\d+),(-?\d+\.\d+)/;
        const match = url.match(regex);
        if (match) {
            return { lat: parseFloat(match[1]), lng: parseFloat(match[2]) };
        }
        console.log("No se pudieron extraer coordenadas de:", url);
        return null;
    };

    // const MapContainer = styled(Box)(({ theme }) => ({
    //     height: '500px',
    //     width: '100%',
    //     marginTop: theme.spacing(3),
    //     marginBottom: theme.spacing(3),
    //     borderRadius: '12px',
    //     overflow: 'hidden',
    //     boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
    // }));

    const InfoSection = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(3),
        backgroundColor: '#f5f5f5',
        borderRadius: '12px',
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        marginBottom: theme.spacing(3),
    }));

    const SectionTitle = styled(Typography)(({ theme }) => ({
        color: '#00b4d8',
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
    }));

    const StyledFormControl = styled(FormControl)(({ theme }) => ({
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#00b4d8',
            },
            '&:hover fieldset': {
                borderColor: '#0095b6',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#00b4d8',
            },
        },
        '& .MuiInputLabel-root': {
            color: '#00b4d8',
        },
    }));

    const IconWrapper = styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        '& > svg': {
            marginRight: theme.spacing(1),
            color: '#00b4d8',
        },
    }));


    const renderMap = () => {
        return (
            <MapContainer
                center={mapCenter}
                zoom={10}
                style={{ height: '500px', width: '100%' }}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {currentRouteGroup?.routes.map((route, index) => {
                    const client = clients.find(c => c.id === route.clientId);
                    const point = client ? extractCoordinates(client.googleMapsLink) : null;
                    if (!point) return null;

                    return (
                        <Marker key={index} position={point}>
                            <Popup>
                                <div>
                                    <strong>{clients.find(c => c.id === route.clientId)?.name}</strong>
                                    <br />
                                    <Button
                                        variant="contained"
                                        onClick={() => handleViewDetails(route, index)}
                                    >
                                        Ver detalles
                                    </Button>

                                    <Circle
                                        center={point}
                                        radius={20} // Ajusta el tamaño del círculo según sea necesario
                                        fillColor="#00b4d8" // Color del círculo
                                        fillOpacity={0.5}
                                    />
                                    <div style={{
                                        position: 'absolute',
                                        transform: 'translate(-50%, -50%)', // Centrar el número
                                        color: 'white',
                                        fontWeight: 'bold',
                                        backgroundColor: 'transparent',
                                        pointerEvents: 'none', // Evitar que el div interfiera con los eventos del mapa
                                    }}>
                                        <Avatar sx={{
                                            bgcolor: (index === 0 || index === currentRouteGroup.routes.length - 1) ? '#FFA500' : '#00b4d8',
                                            color: 'white',
                                            width: 24,
                                            height: 24,
                                        }}>
                                            {index + 1}
                                        </Avatar>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    );
                })}
                {/* Aquí se dibuja la ruta más corta */}
                {decodedRouteCoordinates.length > 0 && (
                    <Polyline
                        positions={decodedRouteCoordinates} // Usar las coordenadas decodificadas
                        color="#00b4d8" // Color de la ruta
                        weight={5} // Grosor de la línea
                        opacity={0.7} // Opacidad de la línea
                    />
                )}
            </MapContainer>
        );
    };

    const renderEditDialog = () => (
        <AnimatePresence>
            {editDialogOpen && (
                <StyledDialog
                    open={editDialogOpen}
                    onClose={handleCloseEditDialog}
                    fullWidth
                    maxWidth="lg"
                    components={{
                        Backdrop: motion.div
                    }}
                    componentsProps={{
                        backdrop: {
                            initial: { opacity: 0 },
                            animate: { opacity: 1 },
                            exit: { opacity: 0 },
                            transition: { duration: 0.3 }
                        } as any
                    }}
                >
                    <motion.div
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.9, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <Dialog open={editDialogOpen} onClose={handleCloseEditDialog} maxWidth="lg" fullWidth>
                            <DialogTitleWrapper>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {format(currentRouteGroup?.createdAt || new Date(), "EEEE, d 'de' MMMM 'de' yyyy", { locale: es })}
                                </Typography>
                                <DateCircle>
                                    {format(currentRouteGroup?.createdAt || new Date(), 'd')}
                                </DateCircle>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {currentRouteGroup?.name}
                                </Typography>
                            </DialogTitleWrapper>
                            <DialogContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        {renderMap()}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InfoSection elevation={3}>
                                            <SectionTitle variant="h6">
                                                <IconWrapper>
                                                    <CalendarToday />
                                                    Información General
                                                </IconWrapper>
                                            </SectionTitle>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={3}>
                                                    <NameInput
                                                        initialName={currentRouteGroup?.name || ''}
                                                        onNameChange={handleNameChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <StyledFormControl fullWidth>
                                                        <InputLabel>Tipo de Ruta</InputLabel>
                                                        <Select
                                                            value={currentRouteGroup?.routeType || 'weekly'}
                                                            onChange={(e) => {
                                                                if (currentRouteGroup) {
                                                                    setCurrentRouteGroup({
                                                                        ...currentRouteGroup,
                                                                        routeType: e.target.value as 'weekly' | 'biweekly' | 'monthly' | 'bimonthly'
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem value="weekly">Semanal</MenuItem>
                                                            <MenuItem value="biweekly">Bisemanal</MenuItem>
                                                            <MenuItem value="monthly">Mensual</MenuItem>
                                                            <MenuItem value="bimonthly">Bimensual</MenuItem>
                                                        </Select>
                                                    </StyledFormControl>
                                                </Grid>
                                                {(currentRouteGroup?.routeType === 'monthly' || currentRouteGroup?.routeType === 'bimonthly') && (
                                                    <>
                                                        <Grid item xs={12} md={3}>
                                                            <StyledFormControl fullWidth>
                                                                <InputLabel>Día de la Semana</InputLabel>
                                                                <Select
                                                                    value={currentRouteGroup?.dayOfWeek || 1}
                                                                    onChange={(e) => {
                                                                        if (currentRouteGroup) {
                                                                            setCurrentRouteGroup({
                                                                                ...currentRouteGroup,
                                                                                dayOfWeek: e.target.value as number
                                                                            });
                                                                        }
                                                                    }}
                                                                >
                                                                    {[1, 2, 3, 4, 5, 6, 7].map((day) => (
                                                                        <MenuItem key={day} value={day}>{['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'][day - 1]}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </StyledFormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                            <StyledFormControl fullWidth>
                                                                <InputLabel>Semana del Mes</InputLabel>
                                                                <Select
                                                                    value={currentRouteGroup?.weekOfMonth || 1}
                                                                    onChange={(e) => {
                                                                        if (currentRouteGroup) {
                                                                            setCurrentRouteGroup({
                                                                                ...currentRouteGroup,
                                                                                weekOfMonth: e.target.value as number
                                                                            });
                                                                        }
                                                                    }}
                                                                >
                                                                    {[1, 2, 3, 4, 5].map((week) => (
                                                                        <MenuItem key={week} value={week}>{week}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </StyledFormControl>
                                                        </Grid>
                                                    </>
                                                )}
                                                {(currentRouteGroup?.routeType === 'weekly' || currentRouteGroup?.routeType === 'biweekly') && (
                                                    <Grid item xs={12} md={3}>
                                                        <StyledFormControl fullWidth>
                                                            <DatePicker
                                                                selected={currentRouteGroup?.createdAt}
                                                                onChange={(date: Date | null) => {
                                                                    if (currentRouteGroup && date) {
                                                                        setCurrentRouteGroup({
                                                                            ...currentRouteGroup,
                                                                            createdAt: date
                                                                        });
                                                                    }
                                                                }}
                                                                customInput={
                                                                    <TextField
                                                                        fullWidth
                                                                        label="Fecha de inicio"
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <Today style={{ color: '#00b4d8', marginRight: '8px' }} />
                                                                            ),
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </StyledFormControl>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </InfoSection>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            backgroundColor: '#f5f5f5',
                                            borderRadius: '12px',
                                            padding: '24px',
                                            boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                                        }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                                <SectionTitle variant="h6">
                                                    <IconWrapper>
                                                        <FormatListBulleted />
                                                        Lista de Pedidos
                                                    </IconWrapper>
                                                </SectionTitle>
                                            </Box>
                                            <DragDropContext onDragEnd={handleDragEnd}>
                                                <Droppable droppableId="routes">
                                                    {(provided) => (
                                                        <Box {...provided.droppableProps} ref={provided.innerRef}>
                                                            {currentRouteGroup?.routes.map((route, index) => (
                                                                <Draggable
                                                                    key={route.id || index}
                                                                    draggableId={route.id || `route-${index}`}
                                                                    index={index}
                                                                    isDragDisabled={index === 0 || index === currentRouteGroup.routes.length - 1} // Deshabilitar arrastre para el primer y último elemento
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <Paper
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            elevation={snapshot.isDragging ? 6 : 1}
                                                                            sx={{
                                                                                mb: 2,
                                                                                p: 2,
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                backgroundColor: snapshot.isDragging ? '#e1f5fe' : 'white',
                                                                                borderLeft: `6px solid ${(index === 0 || index === currentRouteGroup.routes.length - 1)
                                                                                    ? '#FFA500'
                                                                                    : '#00b4d8'
                                                                                    }`,
                                                                                transition: 'all 0.3s ease',
                                                                                '&:hover': { transform: 'translateY(-4px)' }
                                                                            }}
                                                                        >
                                                                            <Avatar sx={{
                                                                                bgcolor: (index === 0 || index === currentRouteGroup.routes.length - 1) ? '#FFA500' : '#00b4d8',
                                                                                color: 'white',
                                                                                mr: 2
                                                                            }}>
                                                                                {index + 1}
                                                                            </Avatar>

                                                                            {/* Nueva columna para tipo_entrega */}
                                                                            <Tooltip title={`Tipo de entrega: ${clients.find(c => c.id === route.clientId)?.tipo_entrega || 'No especificado'}`}>
                                                                                <IconButton sx={{ mr: 2 }}>
                                                                                    {clients.find(c => c.id === route.clientId)?.tipo_entrega === 'Recolección'
                                                                                        ? <RecoleccionIcon sx={{ color: 'green' }} />
                                                                                        : <OllinIcon sx={{
                                                                                            color: '#00b4d8',
                                                                                        }} />}
                                                                                </IconButton>
                                                                            </Tooltip>

                                                                            {/* Nueva columna para status */}
                                                                            <Tooltip title={`Estado: ${route.status || 'No especificado'}`}>
                                                                                <IconButton sx={{ mr: 2 }}>
                                                                                    {route.status === 'pending' && <PendingActions color="warning" />}
                                                                                    {route.status === 'completed' && <CheckCircle color="success" />}
                                                                                    {route.status === 'cancelled' && <Cancel color="error" />}
                                                                                    {!route.status && <PendingActions color="disabled" />}
                                                                                </IconButton>
                                                                            </Tooltip>

                                                                            <FormControl fullWidth sx={{ mr: 2 }}>
                                                                                <InputLabel>Cliente</InputLabel>
                                                                                <Select
                                                                                    value={route.clientId || ''}
                                                                                    onChange={(e) => handleRouteChange(index, 'clientId', e.target.value as string)}
                                                                                    sx={{ '& .MuiOutlinedInput-notchedOutline': { borderColor: '#00b4d8' } }}
                                                                                >
                                                                                    <MenuItem value="" disabled>
                                                                                        <em>Seleccione un cliente</em>
                                                                                    </MenuItem>
                                                                                    {[...availableClients, ...clients.filter(client => client.id === route.clientId)]
                                                                                        .filter((client, index, self) =>
                                                                                            index === self.findIndex((t) => t.id === client.id)
                                                                                        )
                                                                                        .map((client) => (
                                                                                            <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                            </FormControl>
                                                                            <Tooltip title="Ver detalles">
                                                                                <IconButton
                                                                                    onClick={() => handleViewDetails(route, index)}
                                                                                    sx={{
                                                                                        color: '#00b4d8',
                                                                                        '&:hover': { backgroundColor: 'rgba(0, 180, 216, 0.1)' },
                                                                                    }}
                                                                                >
                                                                                    <VisibilityIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title="Reprogramar pedido">
                                                                                <IconButton
                                                                                    onClick={() => handleOpenCalendarDialog(selectedRouteGroup!)} // Cambia aquí para pasar el grupo de rutas
                                                                                    sx={{
                                                                                        color: '#00b4d8',
                                                                                        '&:hover': { backgroundColor: 'rgba(0, 180, 216, 0.1)' },
                                                                                    }}
                                                                                >
                                                                                    <CalendarTodayIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Paper>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </Box>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions sx={{ justifyContent: 'center', px: 3, py: 2, backgroundColor: 'rgba(135, 206, 235, 0.1)' }}>
                                <Button onClick={handleCloseEditDialog} variant="outlined"
                                    sx={{
                                        color: '#87CEEB',
                                        borderColor: '#87CEEB',
                                        '&:hover': {
                                            backgroundColor: 'rgba(135, 206, 235, 0.1)',
                                            borderColor: '#5CACEE',
                                        },
                                        fontWeight: 'bold',
                                        px: 4,
                                        py: 1,
                                    }}>Cancelar</Button>
                                <Button onClick={handleSaveRouteGroup} variant="contained"
                                    sx={{
                                        backgroundColor: '#87CEEB',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#5CACEE',
                                        },
                                        '&:disabled': {
                                            backgroundColor: 'rgba(135, 206, 235, 0.5)',
                                            color: 'rgba(255, 255, 255, 0.7)',
                                        },
                                        fontWeight: 'bold',
                                        px: 4,
                                        py: 1,
                                    }}>Guardar</Button>
                            </DialogActions>
                        </Dialog>
                    </motion.div>
                </StyledDialog>
            )
            }
        </AnimatePresence >
    );

    const handleViewDetails = useCallback((route: Route, index: number) => {
        console.log("handleViewDetails called with route:", route);
        if (!currentRouteGroup) {
            console.log("currentRouteGroup is null or undefined");
            return;
        }

        setSelectedMarker(null);
        setHoveredMarker(null);
        setCurrentRouteIndex(index);
        setSelectedRoute(route);
        console.log("Opening view dialog");
        setViewDialogOpen(true);
    }, [currentRouteGroup]);

    const handleCloseViewDialog = useCallback(() => {
        setSelectedMarker(null);
        setHoveredMarker(null);
        setViewDialogOpen(false);
        setSelectedRoute(null);
    }, []);

    const handleNavigateRoute = useCallback((direction: 'prev' | 'next') => {
        const routesForDay = currentRouteGroup?.routes || [];
        let newIndex = direction === 'prev' ? currentRouteIndex - 1 : currentRouteIndex + 1;

        if (newIndex < 0) {
            newIndex = routesForDay.length - 1;
        } else if (newIndex >= routesForDay.length) {
            newIndex = 0;
        }

        setCurrentRouteIndex(newIndex);
        setSelectedRoute(routesForDay[newIndex]);
    }, [currentRouteIndex, currentRouteGroup]);

    const handleOrderCreated = (newOrder: Order) => {
        if (currentRoute) {
            setCurrentRoute({
                ...currentRoute,
                order: newOrder
            });
        }
        setIsOrderModalOpen(false);
    };

    const handleOpenCalendarDialog = (routeGroup: RouteGroup) => {
        setSelectedRouteGroup(routeGroup);
        setCalendarDialogOpen(true);
    };

    const handleCloseCalendarDialog = () => {
        setCalendarDialogOpen(false);
        setSelectedRoute(null);
    };

    const renderDayCellsWithRoutes = () => {
        const days = getDaysInMonth(currentDate);
        const totalCells = 42;
        const weeks: React.ReactNode[] = [];
        let week: React.ReactNode[] = [];

        for (let i = 0; i < totalCells; i++) {
            const date = i < days.length ? days[i] : null;
            const routeGroups = date ? getRoutesForDay(date) : []; // Fetch route groups for the date
            const isWeekend = date ? (date.getDay() === 0 || date.getDay() === 6) : false;

            week.push(
                <Grid item xs key={i}>
                    <DayCell
                        isCurrentDay={date ? isCurrentDay(date) : false}
                        hasRoutes={routeGroups.length > 0}
                        isWeekend={isWeekend}
                        isCurrentMonth={date ? isCurrentMonth(date) : false}
                    >
                        {date && (
                            <>
                                <DayNumber isCurrentDay={isCurrentDay(date)}>{date.getDate()}</DayNumber>
                                <RouteList>
                                    {routeGroups.map((routeGroup: RouteGroup) => (
                                        <RouteItem
                                            key={routeGroup.id}
                                        >
                                            <Typography variant="caption" noWrap>
                                                {routeGroup.name} {/* Display route group name */}
                                            </Typography>
                                        </RouteItem>
                                    ))}
                                </RouteList>
                            </>
                        )}
                    </DayCell>
                </Grid>
            );

            if (week.length === 7 || i === totalCells - 1) {
                weeks.push(
                    <Grid container spacing={1} key={`week-${weeks.length}`}>
                        {week}
                    </Grid>
                );
                week = [];
            }
        }

        return weeks;
    };

    // Renderizar el diálogo del calendario
    const renderCalendarDialog = () => (
        <Dialog open={calendarDialogOpen} onClose={handleCloseCalendarDialog} fullWidth maxWidth="md">
            <DialogTitle sx={{ bgcolor: '#00b4d8', color: 'white' }}>
                <MonthNavigationDialog>
                    <NavButton onClick={handlePrevMonth} startIcon={<ChevronLeft />}>
                        PREV
                    </NavButton>
                    <MonthYearLabel sx={{ color: 'white' }} >
                        {currentDate.toLocaleString('es-ES', { month: 'long', year: 'numeric' }).toUpperCase()}
                    </MonthYearLabel>
                    <NavButton onClick={handleNextMonth} endIcon={<ChevronRight />}>
                        NEXT
                    </NavButton>
                </MonthNavigationDialog>
            </DialogTitle>
            <br />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} style={{ marginBottom: '16px' }}>
                            {['LUN', 'MAR', 'MIÉ', 'JUE', 'VIE', 'SÁB', 'DOM'].map((day) => (
                                <Grid item xs key={day}>
                                    <WeekdayLabel>
                                        {day}
                                    </WeekdayLabel>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    {/* Renderiza las celdas del calendario con rutas */}
                    {renderDayCellsWithRoutes()}
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', px: 3, py: 2, backgroundColor: 'rgba(135, 206, 235, 0.1)' }}>
                <Button onClick={handleCloseCalendarDialog} variant="outlined"
                    sx={{
                        color: '#87CEEB',
                        borderColor: '#87CEEB',
                        '&:hover': {
                            backgroundColor: 'rgba(135, 206, 235, 0.1)',
                            borderColor: '#5CACEE',
                        },
                        fontWeight: 'bold',
                        px: 4,
                        py: 1,
                    }}
                >Cerrar</Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <CalendarContainer>
            <MonthNavigation>
                <NavButton onClick={handlePrevMonth} startIcon={<ChevronLeft />}>
                    ANTERIOR
                </NavButton>
                <MonthYearLabel>
                    {currentDate.toLocaleString('es-ES', { month: 'long', year: 'numeric' }).toUpperCase()}
                </MonthYearLabel>
                <NavButton onClick={handleNextMonth} endIcon={<ChevronRight />}>
                    SIGUIENTE
                </NavButton>
            </MonthNavigation>
            <Grid container spacing={2} style={{ marginBottom: '16px' }}>
                {['LUN', 'MAR', 'MIÉ', 'JUE', 'VIE', 'SÁB', 'DOM'].map((day) => (
                    <Grid item xs key={day}>
                        <WeekdayLabel>
                            {day}
                        </WeekdayLabel>
                    </Grid>
                ))}
            </Grid>
            {renderDayCells()}
            {renderEditDialog()}
            {/* Renderizar el diálogo del calendario */}
            {renderCalendarDialog()}

            <OrderCreationModal
                open={isOrderModalOpen}
                onClose={() => setIsOrderModalOpen(false)}
                inventoryItems={inventoryItems}
                existingOrder={currentRoute?.order ?? undefined}
                onOrderCreated={handleOrderCreated}
            />
            <SuccessModal
                open={successModalOpen}
                onClose={() => setSuccessModalOpen(false)}
            >
                <Box sx={{ p: 2, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <AnimatedCheckIcon viewBox="0 0 52 52">
                        <AnimatedCircle cx="26" cy="26" r="25" />
                        <path fill="none" stroke="green" strokeWidth="3" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                    </AnimatedCheckIcon>
                    <Typography variant="h6">Cambios guardados exitosamente</Typography>
                </Box>
            </SuccessModal>

            <Dialog
                open={viewDialogOpen}
                onClose={handleCloseViewDialog}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    sx: {
                        borderRadius: '8px',
                        height: 'calc(100% - 64px)',
                        maxHeight: 'none',
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        backgroundColor: '#87CEEB',
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '1.5rem',
                        py: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        padding: '32px',
                    }}
                >
                    <Avatar
                        sx={{
                            bgcolor: currentRouteIndex === 0 || (currentRouteGroup && currentRouteIndex === currentRouteGroup.routes.length - 1) ? '#FFA500' : '#00b4d8',
                            color: 'white',
                            width: 40,
                            height: 40,
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                        }}
                    >
                        {currentRouteIndex + 1}
                    </Avatar>
                </DialogTitle>
                <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 0, position: 'relative' }}>
                    {selectedRoute && (
                        <>
                            <IconButton
                                onClick={() => handleNavigateRoute('prev')}
                                sx={{
                                    position: 'absolute',
                                    left: 16,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    zIndex: 1,
                                }}
                            >
                                <ChevronLeft />
                            </IconButton>
                            <IconButton
                                onClick={() => handleNavigateRoute('next')}
                                sx={{
                                    position: 'absolute',
                                    right: 16,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    zIndex: 1,
                                }}
                            >
                                <ChevronRight />
                            </IconButton>
                            <IconButton
                                onClick={() => handleNavigateRoute('next')}
                                sx={{
                                    position: 'absolute',
                                    right: 16,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    zIndex: 1,
                                }}
                            >
                                <ChevronRight />
                            </IconButton>
                            <Box sx={{ display: 'flex', height: '100%' }}>
                                <Box sx={{ width: '30%', p: 3, borderRight: '1px solid #e0e0e0' }}>
                                    <Typography variant="h6" gutterBottom sx={{ color: '#87CEEB', fontWeight: 'bold' }}>Información Personal</Typography>
                                    <Box sx={{ mb: 2 }}>                                        <Typography variant="subtitle2" color="textSecondary">Nombre</Typography>
                                        <Typography>{clients.find(c => c.id === selectedRoute.clientId)?.name}</Typography>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle2" color="textSecondary">Teléfono</Typography>
                                        <Typography>{clients.find(c => c.id === selectedRoute.clientId)?.phoneNumber}</Typography>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle2" color="textSecondary">Email</Typography>
                                        <Typography>{clients.find(c => c.id === selectedRoute.clientId)?.email}</Typography>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle2" color="textSecondary">Cédula Nacional</Typography>
                                        <Typography>{clients.find(c => c.id === selectedRoute.clientId)?.cedula_nacional}</Typography>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle2" color="textSecondary">Tipo de Documento</Typography>
                                        <Typography>{clients.find(c => c.id === selectedRoute.clientId)?.tipo_documento}</Typography>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle2" color="textSecondary">Tipo de Cliente</Typography>
                                        <Typography>{clients.find(c => c.id === selectedRoute.clientId)?.tipo_client}</Typography>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle2" color="textSecondary">Tipo de Compostera</Typography>
                                        <Typography>{clients.find(c => c.id === selectedRoute.clientId)?.tipo_compostera}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle2" color="textSecondary">Tipo de Entrega</Typography>
                                        {clients.find(c => c.id === selectedRoute.clientId)?.tipo_entrega === 'Ollín' ? (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <ShoppingCartIcon sx={{ color: 'rgb(71, 219, 226)', mr: 1 }} />
                                                <Typography>Ollín</Typography>
                                            </Box>
                                        ) : (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <RecoleccionIcon sx={{ color: 'green', mr: 1 }} />
                                                <Typography>Recolección</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                                <Box sx={{ width: '30%', p: 3, borderRight: '1px solid #e0e0e0' }}>
                                    <Typography variant="h6" gutterBottom sx={{ color: '#87CEEB', fontWeight: 'bold' }}>Ubicación</Typography>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle2" color="textSecondary">Provincia</Typography>
                                        <Typography>{clients.find(c => c.id === selectedRoute.clientId)?.provincia}</Typography>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle2" color="textSecondary">Cantón</Typography>
                                        <Typography>{clients.find(c => c.id === selectedRoute.clientId)?.canton}</Typography>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle2" color="textSecondary">Distrito</Typography>
                                        <Typography>{clients.find(c => c.id === selectedRoute.clientId)?.distrito}</Typography>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle2" color="textSecondary">Municipalidad</Typography>
                                        <Typography>{clients.find(c => c.id === selectedRoute.clientId)?.municipalidades}</Typography>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="subtitle2" color="textSecondary">Señas</Typography>
                                        <Typography>{clients.find(c => c.id === selectedRoute.clientId)?.senas}</Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography variant="subtitle2" color="textSecondary">Nota</Typography>
                                        <Typography>{clients.find(c => c.id === selectedRoute.clientId)?.nota}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        <Button
                                            variant="contained"
                                            startIcon={<MapIcon />}
                                            onClick={() => window.open(clients.find(c => c.id === selectedRoute.clientId)?.googleMapsLink, '_blank')}
                                            disabled={!clients.find(c => c.id === selectedRoute.clientId)?.googleMapsLink}
                                            sx={{
                                                backgroundColor: '#4285F4',
                                                '&:hover': { backgroundColor: '#3367D6' },
                                                flex: 1,
                                            }}
                                        >
                                            Maps
                                        </Button>
                                        <Button
                                            variant="contained"
                                            startIcon={<WazeIcon />}
                                            onClick={() => window.open(clients.find(c => c.id === selectedRoute.clientId)?.wazeLink, '_blank')}
                                            disabled={!clients.find(c => c.id === selectedRoute.clientId)?.wazeLink}
                                            sx={{
                                                backgroundColor: '#33CCFF',
                                                '&:hover': { backgroundColor: '#28A8E0' },
                                                flex: 1,
                                            }}
                                        >
                                            Waze
                                        </Button>
                                    </Box>
                                </Box>
                                <Box sx={{ width: '40%', p: 3, display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="h6" gutterBottom sx={{ color: '#87CEEB', fontWeight: 'bold' }}>Detalles del Pedido</Typography>
                                    <Box sx={{ flex: 1, overflowY: 'auto' }}>
                                        {selectedRoute.order?.items.filter(item => item.quantity > 0).map(item => {
                                            const product = inventoryItems.find(p => p.id === item.inventoryItemId);
                                            return (
                                                <Box key={item.inventoryItemId} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                    <Avatar src={product?.imageUrl} sx={{ width: 40, height: 40, mr: 2 }} />
                                                    <Box>
                                                        <Typography variant="subtitle1">{product?.name}</Typography>
                                                        <Typography variant="body2" color="textSecondary">
                                                            Cantidad: {item.quantity}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                    <Box sx={{ mt: 3 }}>
                                        <Typography variant="h6" gutterBottom sx={{ color: '#87CEEB', fontWeight: 'bold' }}>Mascotas</Typography>
                                        <Grid container spacing={2}>
                                            {['cant_gato', 'cant_perro_mini', 'cant_perro_pequeno', 'cant_perro_mediano', 'cant_perro_grande', 'cant_perro_gigante']
                                                .filter(field => {
                                                    const client = clients.find(c => c.id === selectedRoute.clientId);
                                                    return client && parseInt(client[field as keyof Client] as string, 10) > 0;
                                                })
                                                .map((field) => {
                                                    const client = clients.find(c => c.id === selectedRoute.clientId);
                                                    return (
                                                        <Grid item xs={6} key={field}>
                                                            <Box display="flex" alignItems="center">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/images/${field}.png`}
                                                                    alt={columnNames[field as keyof Client]}
                                                                    style={{ width: '40px', height: '40px', marginRight: '8px' }}
                                                                />
                                                                <Typography variant="body2">{columnNames[field as keyof Client]}: {client?.[field as keyof Client]}</Typography>
                                                            </Box>
                                                        </Grid>
                                                    );
                                                })}
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    )}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', px: 3, py: 2, backgroundColor: 'rgba(135, 206, 235, 0.1)' }}>
                    <Button
                        onClick={handleCloseViewDialog}
                        variant="outlined"
                        sx={{
                            color: '#87CEEB',
                            borderColor: '#87CEEB',
                            '&:hover': {
                                backgroundColor: 'rgba(135, 206, 235, 0.1)',
                                borderColor: '#5CACEE',
                            },
                            fontWeight: 'bold',
                            px: 4,
                            py: 1,
                        }}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </CalendarContainer>
    );
};

export default React.memo(OrdersCalendar);