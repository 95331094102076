import React, { useState, useEffect, Dispatch } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Tabs,
    Tab,
    Box,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Snackbar,
    Alert,
    Typography,
    Divider,
    InputAdornment,
    Container,
    keyframes,
} from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { CloudUpload as CloudUploadIcon, Add as AddIcon, Remove as RemoveIcon, EmojiEmotions as EmojiEmotionsIcon } from '@mui/icons-material';
import { Client, PublicClient } from '../models';
import L, { LatLng } from 'leaflet';
import {
    Update as UpdateIcon,
    Refresh as RefreshIcon,
    Map as MapIcon,
} from '@mui/icons-material';
import WazeIcon from '@mui/icons-material/Directions';
import { FullscreenIcon } from 'lucide-react';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import CreateIcon from '@mui/icons-material/Create';

// Extender la interfaz HTMLElement para incluir propiedades de pantalla completa
interface FullscreenHTMLElement extends HTMLElement {
    mozRequestFullScreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
    msRequestFullscreen?: () => Promise<void>;
}

// Datos estáticos para Costa Rica
const costaRicaData = {
    provinces: ['Alajuela', 'Cartago', 'Guanacaste', 'Heredia', 'Limón', 'Puntarenas', 'San José'],
    cantons: {
        'Alajuela': ['Alajuela', 'San Ramón', 'Grecia', 'San Mateo', 'Atenas', 'Naranjo', 'Palmares', 'Poás', 'Orotina', 'San Carlos', 'Zarcero', 'Sarchí', 'Upala', 'Los Chiles', 'Guatuso', 'Río Cuarto'],
        'Cartago': ['Cartago', 'Paraíso', 'La Unión', 'Jiménez', 'Turrialba', 'Alvarado', 'Oreamuno', 'El Guarco'],
        'Guanacaste': ['Liberia', 'Nicoya', 'Santa Cruz', 'Bagaces', 'Carrillo', 'Cañas', 'Abangares', 'Tilarán', 'Nandayure', 'La Cruz', 'Hojancha'],
        'Heredia': ['Heredia', 'Barva', 'Santo Domingo', 'Santa Bárbara', 'San Rafael', 'San Isidro', 'Belén', 'Flores', 'San Pablo', 'Sarapiquí'],
        'Limón': ['Limón', 'Pococí', 'Siquirres', 'Talamanca', 'Matina', 'Guácimo'],
        'Puntarenas': ['Puntarenas', 'Esparza', 'Buenos Aires', 'Montes de Oro', 'Osa', 'Quepos', 'Golfito', 'Coto Brus', 'Parrita', 'Corredores', 'Garabito'],
        'San José': ['San José', 'Escazú', 'Desamparados', 'Puriscal', 'Tarrazú', 'Aserrí', 'Mora', 'Goicoechea', 'Santa Ana', 'Alajuelita', 'Vázquez de Coronado', 'Acosta', 'Tibás', 'Moravia', 'Montes de Oca', 'Turrubares', 'Dota', 'Curridabat', 'Pérez Zeledón', 'León Cortés Castro']
    },
    districts: {
        'Alajuela': {
            'Alajuela': ['Alajuela', 'San José', 'Carrizal', 'San Antonio', 'Guácima', 'San Isidro', 'Sabanilla', 'San Rafael', 'Río Segundo', 'Desamparados', 'Turrúcares', 'Tambor', 'Garita', 'Sarapiquí'],
            'San Ramón': ['San Ramón', 'Santiago', 'San Juan', 'Piedades Norte', 'Piedades Sur', 'San Rafael', 'San Isidro', 'Ángeles', 'Alfaro', 'Volio', 'Concepción', 'Zapotal', 'Peñas Blancas', 'San Lorenzo'],
            'Grecia': ['Grecia', 'San Isidro', 'San José', 'San Roque', 'Tacares', 'Río Cuarto', 'Puente de Piedra', 'Bolívar'],
            'San Mateo': ['San Mateo', 'Desmonte', 'Jesús María', 'Labrador'],
            'Atenas': ['Atenas', 'Jesús', 'Mercedes', 'San Isidro', 'Concepción', 'San José', 'Santa Eulalia', 'Escobal'],
            'Naranjo': ['Naranjo', 'San Miguel', 'San José', 'Cirrí Sur', 'San Jerónimo', 'San Juan', 'El Rosario', 'Palmitos'],
            'Palmares': ['Palmares', 'Zaragoza', 'Buenos Aires', 'Santiago', 'Candelaria', 'Esquipulas', 'La Granja'],
            'Poás': ['San Pedro', 'San Juan', 'San Rafael', 'Carrillos', 'Sabana Redonda'],
            'Orotina': ['Orotina', 'El Mastate', 'Hacienda Vieja', 'Coyolar', 'La Ceiba'],
            'San Carlos': ['Quesada', 'Florencia', 'Buenavista', 'Aguas Zarcas', 'Venecia', 'Pital', 'La Fortuna', 'La Tigra', 'La Palmera', 'Venado', 'Cutris', 'Monterrey', 'Pocosol'],
            'Zarcero': ['Zarcero', 'Laguna', 'Tapesco', 'Guadalupe', 'Palmira', 'Zapote', 'Brisas'],
            'Sarchí': ['Sarchí Norte', 'Sarchí Sur', 'Toro Amarillo', 'San Pedro', 'Rodríguez'],
            'Upala': ['Upala', 'Aguas Claras', 'San José o Pizote', 'Bijagua', 'Delicias', 'Dos Ríos', 'Yolillal', 'Canalete'],
            'Los Chiles': ['Los Chiles', 'Caño Negro', 'El Amparo', 'San Jorge'],
            'Guatuso': ['San Rafael', 'Buenavista', 'Cote', 'Katira'],
            'Río Cuarto': ['Río Cuarto', 'Santa Rita', 'Santa Isabel']
        },
        'Cartago': {
            'Cartago': ['Oriental', 'Occidental', 'Carmen', 'San Nicolás', 'Aguacaliente', 'Guadalupe', 'Corralillo', 'Tierra Blanca', 'Dulce Nombre', 'Llano Grande', 'Quebradilla'],
            'Paraíso': ['Paraíso', 'Santiago', 'Orosi', 'Cachí', 'Llanos de Santa Lucía'],
            'La Unión': ['Tres Ríos', 'San Diego', 'San Juan', 'San Rafael', 'Concepción', 'Dulce Nombre', 'San Ramón', 'Río Azul'],
            'Jiménez': ['Juan Viñas', 'Tucurrique', 'Pejibaye'],
            'Turrialba': ['Turrialba', 'La Suiza', 'Peralta', 'Santa Cruz', 'Santa Teresita', 'Pavones', 'Tuis', 'Tayutic', 'Santa Rosa', 'Tres Equis', 'La Isabel', 'Chirripó'],
            'Alvarado': ['Pacayas', 'Cervantes', 'Capellades'],
            'Oreamuno': ['San Rafael', 'Cot', 'Potrero Cerrado', 'Cipreses', 'Santa Rosa'],
            'El Guarco': ['El Tejar', 'San Isidro', 'Tobosi', 'Patio de Agua']
        },
        'Guanacaste': {
            'Liberia': ['Liberia', 'Cañas Dulces', 'Mayorga', 'Nacascolo', 'Curubandé'],
            'Nicoya': ['Nicoya', 'Mansión', 'San Antonio', 'Quebrada Honda', 'Sámara', 'Nosara', 'Belén de Nosarita'],
            'Santa Cruz': ['Santa Cruz', 'Bolsón', 'Veintisiete de Abril', 'Tempate', 'Cartagena', 'Cuajiniquil', 'Diriá', 'Cabo Velas', 'Tamarindo'],
            'Bagaces': ['Bagaces', 'La Fortuna', 'Mogote', 'Río Naranjo'],
            'Carrillo': ['Filadelfia', 'Palmira', 'Sardinal', 'Belén'],
            'Cañas': ['Cañas', 'Palmira', 'San Miguel', 'Bebedero', 'Porozal'],
            'Abangares': ['Las Juntas', 'Sierra', 'San Juan', 'Colorado'],
            'Tilarán': ['Tilarán', 'Quebrada Grande', 'Tronadora', 'Santa Rosa', 'Líbano', 'Tierras Morenas', 'Arenal', 'Cabeceras'],
            'Nandayure': ['Carmona', 'Santa Rita', 'Zapotal', 'San Pablo', 'Porvenir', 'Bejuco'],
            'La Cruz': ['La Cruz', 'Santa Cecilia', 'La Garita', 'Santa Elena'],
            'Hojancha': ['Hojancha', 'Monte Romo', 'Puerto Carrillo', 'Huacas', 'Matambú']
        },
        'Heredia': {
            'Heredia': ['Heredia', 'Mercedes', 'San Francisco', 'Ulloa', 'Varablanca'],
            'Barva': ['Barva', 'San Pedro', 'San Pablo', 'San Roque', 'Santa Lucía', 'San José de la Montaña'],
            'Santo Domingo': ['Santo Domingo', 'San Vicente', 'San Miguel', 'Paracito', 'Santo Tomás', 'Santa Rosa', 'Tures', 'Pará'],
            'Santa Bárbara': ['Santa Bárbara', 'San Pedro', 'San Juan', 'Jesús', 'Santo Domingo', 'Purabá'],
            'San Rafael': ['San Rafael', 'San Josecito', 'Santiago', 'Ángeles', 'Concepción'],
            'San Isidro': ['San Isidro', 'San José', 'Concepción', 'San Francisco'],
            'Belén': ['San Antonio', 'La Ribera', 'La Asunción'],
            'Flores': ['San Joaquín', 'Barrantes', 'Llorente'],
            'San Pablo': ['San Pablo', 'Rincón de Sabanilla'],
            'Sarapiquí': ['Puerto Viejo', 'La Virgen', 'Las Horquetas', 'Llanuras del Gaspar', 'Cureña']
        },
        'Limón': {
            'Limón': ['Limón', 'Valle La Estrella', 'Río Blanco', 'Matama'],
            'Pococí': ['Guápiles', 'Jiménez', 'Rita', 'Roxana', 'Cariari', 'Colorado', 'La Colonia'],
            'Siquirres': ['Siquirres', 'Pacuarito', 'Florida', 'Germania', 'El Cairo', 'Alegría', 'Reventazón'],
            'Talamanca': ['Bratsi', 'Sixaola', 'Cahuita', 'Telire'],
            'Matina': ['Matina', 'Batán', 'Carrandi'],
            'Guácimo': ['Guácimo', 'Mercedes', 'Pocora', 'Río Jiménez', 'Duacarí']
        },
        'Puntarenas': {
            'Puntarenas': ['Puntarenas', 'Pitahaya', 'Chomes', 'Lepanto', 'Paquera', 'Manzanillo', 'Guacimal', 'Barranca', 'Monte Verde', 'Isla del Coco', 'Cóbano', 'Chacarita', 'Chira', 'Acapulco', 'El Roble', 'Arancibia'],
            'Esparza': ['Espíritu Santo', 'San Juan Grande', 'Macacona', 'San Rafael', 'San Jerónimo', 'Caldera'],
            'Buenos Aires': ['Buenos Aires', 'Volcán', 'Potrero Grande', 'Boruca', 'Pilas', 'Colinas', 'Chánguena', 'Biolley', 'Brunka'],
            'Montes de Oro': ['Miramar', 'La Unión', 'San Isidro'],
            'Osa': ['Puerto Cortés', 'Palmar', 'Sierpe', 'Bahía Ballena', 'Piedras Blancas', 'Bahía Drake'],
            'Quepos': ['Quepos', 'Savegre', 'Naranjito'],
            'Golfito': ['Golfito', 'Puerto Jiménez', 'Guaycará', 'Pavón'],
            'Coto Brus': ['San Vito', 'Sabalito', 'Aguabuena', 'Limoncito', 'Pittier', 'Gutiérrez Braun'],
            'Parrita': ['Parrita'],
            'Corredores': ['Corredor', 'La Cuesta', 'Canoas', 'Laurel'],
            'Garabito': ['Jacó', 'Tárcoles']
        },
        'San José': {
            'San José': ['Carmen', 'Merced', 'Hospital', 'Catedral', 'Zapote', 'San Francisco de Dos Ríos', 'Uruca', 'Mata Redonda', 'Pavas', 'Hatillo', 'San Sebastián'],
            'Escazú': ['Escazú', 'San Antonio', 'San Rafael'],
            'Desamparados': ['Desamparados', 'San Miguel', 'San Juan de Dios', 'San Rafael Arriba', 'San Antonio', 'Frailes', 'Patarrá', 'San Cristóbal', 'Rosario', 'Damas', 'San Rafael Abajo', 'Gravilias', 'Los Guido'],
            'Puriscal': ['Santiago', 'Mercedes Sur', 'Barbacoas', 'Grifo Alto', 'San Rafael', 'Candelarita', 'Desamparaditos', 'San Antonio', 'Chires'],
            'Tarrazú': ['San Marcos', 'San Lorenzo', 'San Carlos'],
            'Aserrí': ['Aserrí', 'Tarbaca', 'Vuelta de Jorco', 'San Gabriel', 'Legua', 'Monterrey', 'Salitrillos'],
            'Mora': ['Colón', 'Guayabo', 'Tabarcia', 'Piedras Negras', 'Picagres', 'Jaris', 'Quitirrisí'],
            'Goicoechea': ['Guadalupe', 'San Francisco', 'Calle Blancos', 'Mata de Plátano', 'Ipís', 'Rancho Redondo', 'Purral'],
            'Santa Ana': ['Santa Ana', 'Salitral', 'Pozos', 'Uruca', 'Piedades', 'Brasil'],
            'Alajuelita': ['Alajuelita', 'San Josecito', 'San Antonio', 'Concepción', 'San Felipe'],
            'Vázquez de Coronado': ['San Isidro', 'San Rafael', 'Dulce Nombre de Jesús', 'Patalillo', 'Cascajal'],
            'Acosta': ['San Ignacio', 'Guaitil', 'Palmichal', 'Cangrejal', 'Sabanillas'],
            'Tibás': ['San Juan', 'Cinco Esquinas', 'Anselmo Llorente', 'León XIII', 'Colima'],
            'Moravia': ['San Vicente', 'San Jerónimo', 'La Trinidad'],
            'Montes de Oca': ['San Pedro', 'Sabanilla', 'Mercedes', 'San Rafael'],
            'Turrubares': ['San Pablo', 'San Pedro', 'San Juan de Mata', 'San Luis', 'Carara'],
            'Dota': ['Santa María', 'Jardín', 'Copey'],
            'Curridabat': ['Curridabat', 'Granadilla', 'Sánchez', 'Tirrases'],
            'Pérez Zeledón': ['San Isidro de El General', 'El General', 'Daniel Flores', 'Rivas', 'San Pedro', 'Platanares', 'Pejibaye', 'Cajón', 'Barú', 'Río Nuevo', 'Páramo', 'La Amistad'],
            'León Cortés Castro': ['San Pablo', 'San Andrés', 'Llano Bonito', 'San Isidro', 'Santa Cruz', 'San Antonio']
        }
    },
    municipalities: {
        'Alajuela': [
            "Municipalidad de Alajuela",
            "Municipalidad de San Ramón",
            "Municipalidad de Grecia",
            "Municipalidad de San Mateo",
            "Municipalidad de Atenas",
            "Municipalidad de Naranjo",
            "Municipalidad de Palmares",
            "Municipalidad de Poás",
            "Municipalidad de Orotina",
            "Municipalidad de San Carlos",
            "Municipalidad de Zarcero",
            "Municipalidad de Sarchí",
            "Municipalidad de Upala",
            "Municipalidad de Los Chiles",
            "Municipalidad de Guatuso",
            "Municipalidad de Río Cuarto"
        ],
        'Cartago': [
            "Municipalidad de Cartago",
            "Municipalidad de Paraíso",
            "Municipalidad de La Unión",
            "Municipalidad de Jiménez",
            "Municipalidad de Turrialba",
            "Municipalidad de Alvarado",
            "Municipalidad de Oreamuno",
            "Municipalidad de El Guarco"
        ],
        'Guanacaste': [
            "Municipalidad de Liberia",
            "Municipalidad de Nicoya",
            "Municipalidad de Santa Cruz",
            "Municipalidad de Bagaces",
            "Municipalidad de Carrillo",
            "Municipalidad de Cañas",
            "Municipalidad de Abangares",
            "Municipalidad de Tilarán",
            "Municipalidad de Nandayure",
            "Municipalidad de La Cruz",
            "Municipalidad de Hojancha"
        ],
        'Heredia': [
            "Municipalidad de Heredia",
            "Municipalidad de Barva",
            "Municipalidad de Santo Domingo",
            "Municipalidad de Santa Bárbara",
            "Municipalidad de San Rafael",
            "Municipalidad de San Isidro",
            "Municipalidad de Belén",
            "Municipalidad de Flores",
            "Municipalidad de San Pablo",
            "Municipalidad de Sarapiquí"
        ],
        'Limón': [
            "Municipalidad de Limón",
            "Municipalidad de Pococí",
            "Municipalidad de Siquirres",
            "Municipalidad de Talamanca",
            "Municipalidad de Matina",
            "Municipalidad de Guácimo"
        ],
        'Puntarenas': [
            "Municipalidad de Puntarenas",
            "Municipalidad de Esparza",
            "Municipalidad de Buenos Aires",
            "Municipalidad de Montes de Oro",
            "Municipalidad de Osa",
            "Municipalidad de Quepos",
            "Municipalidad de Golfito",
            "Municipalidad de Coto Brus",
            "Municipalidad de Parrita",
            "Municipalidad de Corredores",
            "Municipalidad de Garabito"
        ],
        'San José': [
            "Municipalidad de San José",
            "Municipalidad de Escazú",
            "Municipalidad de Desamparados",
            "Municipalidad de Puriscal",
            "Municipalidad de Tarrazú",
            "Municipalidad de Aserrí",
            "Municipalidad de Mora",
            "Municipalidad de Goicoechea",
            "Municipalidad de Santa Ana",
            "Municipalidad de Alajuelita",
            "Municipalidad de Vázquez de Coronado",
            "Municipalidad de Acosta",
            "Municipalidad de Tibás",
            "Municipalidad de Moravia",
            "Municipalidad de Montes de Oca",
            "Municipalidad de Turrubares",
            "Municipalidad de Dota",
            "Municipalidad de Curridabat",
            "Municipalidad de Pérez Zeledón",
            "Municipalidad de León Cortés Castro"
        ]
    }
};

interface Boundary {
    minLat: number;
    maxLat: number;
    minLng: number;
    maxLng: number;
}

interface LocationData {
    provincia: string;
    canton: string;
    distrito: string;
}

const cantonBoundaries: { [key: string]: { [key: string]: Boundary } } = {
    'San José': {
        'San José': { minLat: 9.9, maxLat: 10.0, minLng: -84.1, maxLng: -84.0 },
        'Escazú': { minLat: 9.9, maxLat: 9.95, minLng: -84.2, maxLng: -84.1 },
        'Desamparados': { minLat: 9.85, maxLat: 9.95, minLng: -84.1, maxLng: -84.0 },
        'Puriscal': { minLat: 9.75, maxLat: 9.9, minLng: -84.4, maxLng: -84.2 },
        'Tarrazú': { minLat: 9.6, maxLat: 9.75, minLng: -84.1, maxLng: -83.9 },
        'Aserrí': { minLat: 9.8, maxLat: 9.9, minLng: -84.15, maxLng: -84.05 },
        'Mora': { minLat: 9.85, maxLat: 9.95, minLng: -84.3, maxLng: -84.2 },
        'Goicoechea': { minLat: 9.95, maxLat: 10.05, minLng: -84.05, maxLng: -83.95 },
        'Santa Ana': { minLat: 9.9, maxLat: 10.0, minLng: -84.25, maxLng: -84.15 },
        'Alajuelita': { minLat: 9.85, maxLat: 9.95, minLng: -84.15, maxLng: -84.05 },
        'Vázquez de Coronado': { minLat: 10.0, maxLat: 10.1, minLng: -84.0, maxLng: -83.9 },
        'Acosta': { minLat: 9.7, maxLat: 9.85, minLng: -84.3, maxLng: -84.1 },
        'Tibás': { minLat: 9.95, maxLat: 10.0, minLng: -84.1, maxLng: -84.05 },
        'Moravia': { minLat: 10.0, maxLat: 10.05, minLng: -84.05, maxLng: -83.95 },
        'Montes de Oca': { minLat: 9.95, maxLat: 10.0, minLng: -84.05, maxLng: -83.95 },
        'Turrubares': { minLat: 9.75, maxLat: 9.9, minLng: -84.6, maxLng: -84.4 },
        'Dota': { minLat: 9.55, maxLat: 9.7, minLng: -84.0, maxLng: -83.8 },
        'Curridabat': { minLat: 9.9, maxLat: 9.95, minLng: -84.05, maxLng: -83.95 },
        'Pérez Zeledón': { minLat: 9.2, maxLat: 9.5, minLng: -83.8, maxLng: -83.4 },
        'León Cortés Castro': { minLat: 9.65, maxLat: 9.8, minLng: -84.1, maxLng: -83.9 }
    },
    'Alajuela': {
        'Alajuela': { minLat: 10.0, maxLat: 10.1, minLng: -84.3, maxLng: -84.1 },
        'San Ramón': { minLat: 10.05, maxLat: 10.2, minLng: -84.6, maxLng: -84.4 },
        'Grecia': { minLat: 10.05, maxLat: 10.2, minLng: -84.35, maxLng: -84.2 },
        'San Mateo': { minLat: 9.9, maxLat: 10.0, minLng: -84.6, maxLng: -84.45 },
        'Atenas': { minLat: 9.95, maxLat: 10.05, minLng: -84.45, maxLng: -84.35 },
        'Naranjo': { minLat: 10.1, maxLat: 10.2, minLng: -84.45, maxLng: -84.35 },
        'Palmares': { minLat: 10.05, maxLat: 10.15, minLng: -84.5, maxLng: -84.4 },
        'Poás': { minLat: 10.15, maxLat: 10.25, minLng: -84.3, maxLng: -84.2 },
        'Orotina': { minLat: 9.9, maxLat: 10.0, minLng: -84.6, maxLng: -84.5 },
        'San Carlos': { minLat: 10.3, maxLat: 10.9, minLng: -84.6, maxLng: -84.0 },
        'Zarcero': { minLat: 10.15, maxLat: 10.25, minLng: -84.45, maxLng: -84.35 },
        'Sarchí': { minLat: 10.1, maxLat: 10.2, minLng: -84.4, maxLng: -84.3 },
        'Upala': { minLat: 10.8, maxLat: 11.0, minLng: -85.2, maxLng: -84.8 },
        'Los Chiles': { minLat: 10.8, maxLat: 11.1, minLng: -84.8, maxLng: -84.4 },
        'Guatuso': { minLat: 10.6, maxLat: 10.8, minLng: -84.9, maxLng: -84.6 },
        'Río Cuarto': { minLat: 10.3, maxLat: 10.5, minLng: -84.4, maxLng: -84.2 }
    },
    'Cartago': {
        'Cartago': { minLat: 9.8, maxLat: 9.9, minLng: -84.0, maxLng: -83.9 },
        'Paraíso': { minLat: 9.8, maxLat: 9.9, minLng: -83.9, maxLng: -83.8 },
        'La Unión': { minLat: 9.9, maxLat: 10.0, minLng: -84.0, maxLng: -83.9 },
        'Jiménez': { minLat: 9.7, maxLat: 9.8, minLng: -83.8, maxLng: -83.7 },
        'Turrialba': { minLat: 9.8, maxLat: 10.1, minLng: -83.7, maxLng: -83.5 },
        'Alvarado': { minLat: 9.9, maxLat: 10.0, minLng: -83.85, maxLng: -83.75 },
        'Oreamuno': { minLat: 9.9, maxLat: 10.0, minLng: -83.9, maxLng: -83.8 },
        'El Guarco': { minLat: 9.75, maxLat: 9.85, minLng: -84.0, maxLng: -83.9 }
    },
    'Heredia': {
        'Heredia': { minLat: 10.0, maxLat: 10.05, minLng: -84.15, maxLng: -84.1 },
        'Barva': { minLat: 10.05, maxLat: 10.1, minLng: -84.15, maxLng: -84.1 },
        'Santo Domingo': { minLat: 10.0, maxLat: 10.05, minLng: -84.1, maxLng: -84.05 },
        'Santa Bárbara': { minLat: 10.05, maxLat: 10.1, minLng: -84.2, maxLng: -84.15 },
        'San Rafael': { minLat: 10.05, maxLat: 10.1, minLng: -84.15, maxLng: -84.1 },
        'San Isidro': { minLat: 10.05, maxLat: 10.1, minLng: -84.1, maxLng: -84.05 },
        'Belén': { minLat: 9.95, maxLat: 10.0, minLng: -84.2, maxLng: -84.15 },
        'Flores': { minLat: 10.0, maxLat: 10.05, minLng: -84.2, maxLng: -84.15 },
        'San Pablo': { minLat: 10.0, maxLat: 10.05, minLng: -84.1, maxLng: -84.05 },
        'Sarapiquí': { minLat: 10.3, maxLat: 10.7, minLng: -84.2, maxLng: -83.8 }
    },
    'Guanacaste': {
        'Liberia': { minLat: 10.5, maxLat: 10.7, minLng: -85.5, maxLng: -85.3 },
        'Nicoya': { minLat: 10.0, maxLat: 10.2, minLng: -85.5, maxLng: -85.3 },
        'Santa Cruz': { minLat: 10.2, maxLat: 10.4, minLng: -85.7, maxLng: -85.5 },
        'Bagaces': { minLat: 10.4, maxLat: 10.6, minLng: -85.3, maxLng: -85.1 },
        'Carrillo': { minLat: 10.3, maxLat: 10.5, minLng: -85.6, maxLng: -85.4 },
        'Cañas': { minLat: 10.3, maxLat: 10.5, minLng: -85.1, maxLng: -84.9 },
        'Abangares': { minLat: 10.1, maxLat: 10.3, minLng: -85.0, maxLng: -84.8 },
        'Tilarán': { minLat: 10.3, maxLat: 10.5, minLng: -84.9, maxLng: -84.7 },
        'Nandayure': { minLat: 9.9, maxLat: 10.1, minLng: -85.3, maxLng: -85.1 },
        'La Cruz': { minLat: 10.9, maxLat: 11.1, minLng: -85.7, maxLng: -85.5 },
        'Hojancha': { minLat: 10.0, maxLat: 10.2, minLng: -85.4, maxLng: -85.2 }
    },
    'Puntarenas': {
        'Puntarenas': { minLat: 9.9, maxLat: 10.1, minLng: -84.9, maxLng: -84.7 },
        'Esparza': { minLat: 9.9, maxLat: 10.1, minLng: -84.7, maxLng: -84.5 },
        'Buenos Aires': { minLat: 9.0, maxLat: 9.2, minLng: -83.4, maxLng: -83.2 },
        'Montes de Oro': { minLat: 10.0, maxLat: 10.2, minLng: -84.6, maxLng: -84.4 },
        'Osa': { minLat: 8.8, maxLat: 9.0, minLng: -83.6, maxLng: -83.4 },
        'Quepos': { minLat: 9.4, maxLat: 9.6, minLng: -84.2, maxLng: -84.0 },
        'Golfito': { minLat: 8.6, maxLat: 8.8, minLng: -83.2, maxLng: -83.0 },
        'Coto Brus': { minLat: 8.9, maxLat: 9.1, minLng: -83.1, maxLng: -82.9 },
        'Parrita': { minLat: 9.5, maxLat: 9.7, minLng: -84.4, maxLng: -84.2 },
        'Corredores': { minLat: 8.5, maxLat: 8.7, minLng: -83.0, maxLng: -82.8 },
        'Garabito': { minLat: 9.6, maxLat: 9.8, minLng: -84.7, maxLng: -84.5 }
    },
    'Limón': {
        'Limón': { minLat: 9.9, maxLat: 10.1, minLng: -83.1, maxLng: -82.9 },
        'Pococí': { minLat: 10.3, maxLat: 10.5, minLng: -83.7, maxLng: -83.5 },
        'Siquirres': { minLat: 10.1, maxLat: 10.3, minLng: -83.5, maxLng: -83.3 },
        'Talamanca': { minLat: 9.5, maxLat: 9.7, minLng: -82.9, maxLng: -82.7 },
        'Matina': { minLat: 10.0, maxLat: 10.2, minLng: -83.3, maxLng: -83.1 },
        'Guácimo': { minLat: 10.2, maxLat: 10.4, minLng: -83.7, maxLng: -83.5 }
    }
};

const findLocationInCostaRicaData = (location: { lat: number; lng: number }): LocationData => {
    // Definimos límites más precisos para cada provincia
    const provinceBoundaries: { [key: string]: Boundary } = {
        'San José': { minLat: 9.5, maxLat: 10.2, minLng: -84.5, maxLng: -83.5 },
        'Alajuela': { minLat: 10.0, maxLat: 10.9, minLng: -84.5, maxLng: -83.8 },
        'Cartago': { minLat: 9.7, maxLat: 10.1, minLng: -84.0, maxLng: -83.5 },
        'Heredia': { minLat: 10.0, maxLat: 10.5, minLng: -84.2, maxLng: -83.8 },
        'Guanacaste': { minLat: 10.0, maxLat: 11.2, minLng: -85.8, maxLng: -84.8 },
        'Puntarenas': { minLat: 8.4, maxLat: 10.1, minLng: -85.0, maxLng: -83.0 },
        'Limón': { minLat: 9.0, maxLat: 10.3, minLng: -83.5, maxLng: -82.5 }
    };

    // Función para calcular la distancia entre dos puntos (Fórmula de Haversine)
    const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
        const R = 6371; // Radio de la Tierra en km
        const dLat = (lat2 - lat1) * Math.PI / 180;
        const dLon = (lon2 - lon1) * Math.PI / 180;
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distancia en km
    };

    // Encontrar la provincia
    let provincia = 'Desconocida';
    for (const [name, bounds] of Object.entries(provinceBoundaries)) {
        if (location.lat >= bounds.minLat && location.lat <= bounds.maxLat &&
            location.lng >= bounds.minLng && location.lng <= bounds.maxLng) {
            provincia = name;
            break;
        }
    }

    // Si no se encontró una provincia, retornar valores por defecto
    if (provincia === 'Desconocida') {
        return { provincia: 'Desconocida', canton: 'Desconocido', distrito: 'Desconocido' };
    }

    // Encontrar el cantón más cercano
    let canton = 'Desconocido';
    let minCantonDistance = Infinity;
    for (const [cantonName, bounds] of Object.entries(cantonBoundaries[provincia] || {})) {
        const cantonLat = (bounds.minLat + bounds.maxLat) / 2;
        const cantonLng = (bounds.minLng + bounds.maxLng) / 2;
        const distance = calculateDistance(location.lat, location.lng, cantonLat, cantonLng);
        if (distance < minCantonDistance) {
            minCantonDistance = distance;
            canton = cantonName;
        }
    }

    // Obtener los distritos del cantón
    const provinciaDistricts = costaRicaData.districts[provincia as keyof typeof costaRicaData.districts];
    const distritos = provinciaDistricts && canton in provinciaDistricts ? provinciaDistricts[canton as keyof typeof provinciaDistricts] : [];

    // Encontrar el distrito más cercano
    let distrito = 'Desconocido';
    let minDistritoDistance = Infinity;
    for (const d of distritos) {
        // Aquí usamos el centro del cantón como aproximación para el distrito
        const cantonBounds = cantonBoundaries[provincia][canton];
        const distritoLat = (cantonBounds.minLat + cantonBounds.maxLat) / 2;
        const distritoLng = (cantonBounds.minLng + cantonBounds.maxLng) / 2;
        const distance = calculateDistance(location.lat, location.lng, distritoLat, distritoLng);
        if (distance < minDistritoDistance) {
            minDistritoDistance = distance;
            distrito = d;
        }
    }

    return { provincia, canton, distrito };
};

type Province = keyof typeof costaRicaData.districts;
type Canton = keyof typeof costaRicaData.districts[Province];

const MapEventHandler = ({ onClick }: { onClick: (e: L.LeafletMouseEvent) => void }) => {
    const map = useMap();

    // Agregar el evento de clic al mapa
    map.on('click', onClick);

    // Limpiar el evento cuando el componente se desmonte
    React.useEffect(() => {
        return () => {
            map.off('click', onClick);
        };
    }, [map, onClick]);

    return null; // Este componente no necesita renderizar nada
};

interface PublicClientDialogProps {
    open: boolean;
    onClose: () => void;
    handleSavePublicClient: (client: PublicClient) => Promise<void>;
}

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const PublicClientDialog: React.FC<PublicClientDialogProps> = ({
    open,
    onClose,
    handleSavePublicClient // Corrected prop name
}) => {
    const [availableDistricts, setAvailableDistricts] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null); // Nuevo estado para mensajes de éxito
    const [isHovered, setIsHovered] = useState(false);
    const [currentPublicClient, setCurrentPublicClient] = useState<PublicClient | null>(null);
    const [availableMunicipalities, setAvailableMunicipalities] = useState<string[]>([]);

    const publicColumnNames: { [key in keyof PublicClient]: string } = {
        name: 'Nombre',
        phoneNumber: 'Teléfono',
        email: 'Correo Electrónico',
        googleMapsLink: 'Enlace Google Maps',
        wazeLink: 'Enlace Waze',
        id: 'ID',
        cedula_nacional: 'Cédula Nacional',
        second_phoneNumber: 'Teléfono Secundario',
        tipo_documento: 'Tipo de Documento',
        tipo_client: 'Tipo de Cliente',
        tipo_compostera: 'Tipo de Compostera',
        tipo_entrega: 'Tipo de Entrega',
        senas: 'Señas',
        nota: 'Nota',
        imageUrl: 'URL de Imagen',
        provincia: 'Provincia',
        canton: 'Cantón',
        distrito: 'Distrito',
        municipalidades: 'Municipalidad',
        cant_gato: 'Gato 0-6Kg',
        cant_perro_mini: 'Mini 0-6Kg',
        cant_perro_pequeno: 'Pequeño 6-10Kg',
        cant_perro_mediano: 'Mediano 10-25Kg',
        cant_perro_grande: 'Grande 25-45Kg',
        cant_perro_gigante: 'Gigante +45Kg',
        status_create_public_client: 'Estado de Creación',
    };

    useEffect(() => {
        // Cargar el cliente público actual al montar el componente
        const clientToLoad: PublicClient | null = {
            name: '',
            phoneNumber: '',
            email: '',
            googleMapsLink: '',
            wazeLink: '',
            cedula_nacional: '',
            second_phoneNumber: '',
            tipo_documento: 'Nacional',
            tipo_client: 'Regular',
            tipo_compostera: 'S',
            tipo_entrega: 'Ollín',
            senas: '',
            nota: '',
            imageUrl: '',
            provincia: '',
            canton: '',
            distrito: '',
            municipalidades: '',
            cant_gato: '0',
            cant_perro_mini: '0',
            cant_perro_pequeno: '0',
            cant_perro_mediano: '0',
            cant_perro_grande: '0',
            cant_perro_gigante: '0',
            status_create_public_client: 'pending',
        };

        setCurrentPublicClient(clientToLoad); // Establecer el cliente actual

        // Actualizar distritos disponibles si hay un cliente público
        if (clientToLoad.provincia) {
            const districts = Object.keys(costaRicaData.districts[clientToLoad.provincia as keyof typeof costaRicaData.districts] || []);
            setAvailableDistricts(districts); // Set available districts to an array of district names
        } else {
            setAvailableDistricts([]); // Limpiar distritos si no hay provincia
        }
    }, []); // Dependencias vacías para que se ejecute solo al montar

    const validateName = (name: string) => /^[A-Za-z0-9\s]{1,29}$/.test(name);
    const validatePhone = (phone: string) => /^\d{1,13}$/.test(phone);
    const validateCedula = (cedula: string) => /^\d{1,13}$/.test(cedula);
    const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validateSenasNota = (input: string) => /^.{0,250}$/.test(input);
    const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number } | null>(null);
    const notificationSound = new Audio(`${process.env.PUBLIC_URL}/audio/Notification.mp3`); // Update the path accordingly

    // Add state for the confirmation dialog
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    // Function to handle client creation
    const handleCreateClient = async () => {
        setOpenConfirmationDialog(true); // Open confirmation dialog
    };

    // Function to confirm client creation
    const confirmCreateClient = async () => {
        await handleSavePublicClient(currentPublicClient!); // Call the existing save function
        setOpenConfirmationDialog(false); // Close the dialog

        // Play notification sound
        notificationSound.play().catch(error => {
            console.error("Error playing sound:", error);
        });

        // Show notification
        setSuccessMessage("Cliente creado exitosamente!"); // Set success message
        setTimeout(() => {
            window.location.reload(); // Reload the page after a delay
        }, 2000); // Adjust delay as needed
    };

    // Function to update location from Google Maps or Waze link
    const updateLocationFromLink = (link: string) => {
        const googleMapsRegex = /@?(-?\d+\.\d+),(-?\d+\.\d+)/; // Regex to match lat, lng in Google Maps links
        const wazeRegex = /ll=(-?\d+\.\d+),(-?\d+\.\d+)/; // Regex to match lat, lng in Waze links

        let match = link.match(googleMapsRegex) || link.match(wazeRegex);
        if (match) {
            const lat = parseFloat(match[1]);
            const lng = parseFloat(match[2]);
            setCurrentPublicClient((curr: PublicClient | null) => {
                if (!curr) return null;
                return {
                    ...curr,
                    location: [lat, lng], // Update the location
                };
            });
        }
    };

    const isPublicFormValid = () => {
        return (
            currentPublicClient &&
            currentPublicClient.phoneNumber.trim() !== '' &&
            currentPublicClient.name.trim() !== '' &&
            currentPublicClient.email.trim() !== '' &&
            selectedLocation
        );
    };

    // Function to handle input changes
    const handlePublicInputChange = (field: keyof PublicClient, value: string) => {
        setCurrentPublicClient((curr: PublicClient | null) => {
            if (!curr) return null;
            return { ...curr, [field]: value }; // Update the field
        });
    };

    const handleMapClick = (event: any) => {
        const { lat, lng } = event.latlng; // Asegúrate de que 'latlng' esté disponible
        const newGoogleMapsLink = `https://www.google.com/maps?q=${lat},${lng}`;
        const newWazeLink = `https://waze.com/ul?ll=${lat},${lng}&navigate=yes`;

        setSelectedLocation({ lat, lng }); // Actualiza la ubicación seleccionada
        setCurrentPublicClient(curr => {
            if (!curr) return null;
            return {
                ...curr,
                googleMapsLink: newGoogleMapsLink,
                wazeLink: newWazeLink,
                // Aquí puedes agregar más campos si es necesario
            };
        });

        // Nueva lógica para actualizar manualmente la ubicación
        const location = findLocationInCostaRicaData({ lat, lng });
        setCurrentPublicClient(curr => {
            if (!curr) return null;
            return {
                ...curr,
                provincia: location.provincia,
                canton: location.canton,
                distrito: location.distrito,
                // Mantenemos el valor actual de municipalidades en lugar de actualizarlo
                municipalidades: curr.municipalidades
            };
        });

        // Actualizamos los selectores
        if (location.provincia in costaRicaData.districts) {
            const provinciaDistricts = costaRicaData.districts[location.provincia as keyof typeof costaRicaData.districts];
            if (location.canton in provinciaDistricts) {
                setAvailableDistricts(provinciaDistricts[location.canton as keyof typeof provinciaDistricts] || []);
            } else {
                setAvailableDistricts([]); // Si el cantón no se encuentra, limpiar los distritos disponibles
            }
        }
    };

    const handleQuantityChangePublic = (field: string, increment: boolean) => { // Nueva función para PublicClient
        setCurrentPublicClient((curr: PublicClient | null) => {
            if (!curr) return null;
            const currentValue = parseInt(curr[field as keyof PublicClient] as string) || 0;
            const newValue = increment ? currentValue + 1 : Math.max(0, currentValue - 1);
            return { ...curr, [field]: newValue.toString() };
        });
    };

    const handleImageUploadPublic = (event: React.ChangeEvent<HTMLInputElement>) => { // Nueva función para PublicClient
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCurrentPublicClient((curr: PublicClient | null) => curr ? { ...curr, imageUrl: reader.result as string } : null);
            };
            reader.readAsDataURL(file);
        }
    };

    // // Función para manejar la selección de ubicación
    // const handleLocationSelect = (location: { lat: number; lng: number }) => {
    //     setCurrentPublicClient((curr: PublicClient | null) => {
    //         if (!curr) return null;

    //         return {
    //             ...curr,
    //             googleMapsLink: `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`,
    //             wazeLink: `https://www.waze.com/ul?ll=${location.lat},${location.lng}&navigate=yes`
    //         };
    //     });

    //     setIsLocationSelected(true);
    // };


    const handleClearError = () => {
        setError(null);
    };

    const isFormValid = () => {
        return (
            currentPublicClient &&
            validateName(currentPublicClient.name) &&
            validatePhone(currentPublicClient.phoneNumber) &&
            validateEmail(currentPublicClient.email) &&
            validateCedula(currentPublicClient.cedula_nacional) &&
            currentPublicClient.provincia &&
            currentPublicClient.canton &&
            currentPublicClient.distrito
        );
    };

    return (
        <Container sx={{ padding: 2 }}>
            <Box sx={{
                width: '100%',
                padding: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                borderRadius: '8px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
            }}>
                <Typography variant="h4" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                    background: 'linear-gradient(90deg, rgba(135, 206, 235, 1) 0%, rgba(135, 206, 235, 1) 100%)', // Color actualizado
                    color: 'white',
                    padding: '10px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                    justifyContent: 'center', // Centrar el contenido
                }}>
                    <img src={`${process.env.PUBLIC_URL}/images/ollin_logo.png`} alt="Logo Ollin" style={{ width: '100px', height: '50px', marginRight: '10px', marginBottom: '8px', justifySelf: 'center' }} /> {/* Logo agregado */}
                    🐶 Registro de Cliente 🐾
                </Typography>
                <Box sx={{ height: '750px', overflow: 'auto', scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none' } }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sx={{ justifyContent: 'center' }}>
                                        <h3 style={{ textAlign: 'center', backgroundColor: 'rgb(135, 206, 235)', color: 'white', padding: '10px', borderRadius: '5px' }}>
                                            DATOS PERSONALES Y UBICACIÓN
                                        </h3>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            label="Nombre"
                                            type="text"
                                            fullWidth
                                            error={!!currentPublicClient?.name && !validateName(currentPublicClient.name)} // Validación de nombre
                                            helperText={!!currentPublicClient?.name && !validateName(currentPublicClient.name) ? 'El nombre solo debe contener letras y números, y tener un máximo de 29 caracteres.' : ''}
                                            value={currentPublicClient?.name || ''}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setCurrentPublicClient(curr => curr ? { ...curr, name: value } : null); // Asegúrate de que el estado se actualice
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            margin="dense"
                                            label="Teléfono"
                                            type="tel"
                                            fullWidth
                                            error={!!currentPublicClient?.phoneNumber && !validatePhone(currentPublicClient.phoneNumber)} // Validación de teléfono
                                            helperText={!!currentPublicClient?.phoneNumber && !validatePhone(currentPublicClient.phoneNumber) ? 'El teléfono debe ser numérico y tener hasta 13 dígitos.' : ''}
                                            value={currentPublicClient?.phoneNumber || ''}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setCurrentPublicClient(curr => curr ? { ...curr, phoneNumber: value } : null);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            margin="dense"
                                            label="Correo Electrónico"
                                            type="email"
                                            fullWidth
                                            error={!!currentPublicClient?.email && !validateEmail(currentPublicClient.email)} // Validación de correo electrónico
                                            helperText={!!currentPublicClient?.email && !validateEmail(currentPublicClient.email) ? 'Ingrese un correo electrónico válido.' : ''}
                                            value={currentPublicClient?.email || ''}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setCurrentPublicClient(curr => curr ? { ...curr, email: value } : null);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            margin="dense"
                                            label="Cédula Nacional"
                                            type="text"
                                            fullWidth
                                            error={!!currentPublicClient?.cedula_nacional && !validateCedula(currentPublicClient.cedula_nacional)} // Validación de cédula
                                            helperText={!!currentPublicClient?.cedula_nacional && !validateCedula(currentPublicClient.cedula_nacional) ? 'La cédula debe ser numérica y tener hasta 13 dígitos.' : ''}
                                            value={currentPublicClient?.cedula_nacional || ''}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setCurrentPublicClient(curr => curr ? { ...curr, cedula_nacional: value } : null);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth margin="dense">
                                            <InputLabel>Provincia</InputLabel>
                                            <Select
                                                value={currentPublicClient?.provincia || ''}
                                                onChange={(e) => {
                                                    const selectedProvince = e.target.value as string;
                                                    setCurrentPublicClient((curr: PublicClient | null) => curr ? { ...curr, provincia: selectedProvince, canton: '', distrito: '' } : null);
                                                    const districts = Object.keys(costaRicaData.districts[selectedProvince as keyof typeof costaRicaData.districts] || []);
                                                    setAvailableDistricts(districts); // Set available districts to an array of district names
                                                }}
                                            >
                                                {costaRicaData.provinces.map((province) => (
                                                    <MenuItem key={province} value={province}>{province}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth margin="dense">
                                            <InputLabel>Cantón</InputLabel>
                                            <Select
                                                value={currentPublicClient?.canton || ''}
                                                onChange={(e) => {
                                                    const selectedCanton = e.target.value as string;
                                                    setCurrentPublicClient((curr: PublicClient | null) => curr ? { ...curr, canton: selectedCanton, distrito: '' } : null);
                                                    const districts = Object.keys(costaRicaData.districts[currentPublicClient?.provincia as keyof typeof costaRicaData.districts] || {});
                                                    setAvailableDistricts(districts); // Set available districts to an array of district names
                                                }}
                                            >
                                                {availableDistricts.map((canton) => (
                                                    <MenuItem key={canton} value={canton}>{canton}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth margin="dense">
                                            <InputLabel>Distrito</InputLabel>
                                            <Select
                                                value={currentPublicClient?.distrito || ''}
                                                onChange={(e) => setCurrentPublicClient((curr: PublicClient | null) => curr ? { ...curr, distrito: e.target.value } : null)}
                                            >
                                                {availableDistricts.map((district) => (
                                                    <MenuItem key={district} value={district}>{district}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            margin="dense"
                                            label="Señas"
                                            type="text"
                                            fullWidth
                                            multiline
                                            rows={2}
                                            error={!!currentPublicClient?.senas && !validateSenasNota(currentPublicClient.senas)} // Validación de señas
                                            helperText={!!currentPublicClient?.senas && !validateSenasNota(currentPublicClient.senas) ? 'Las señas pueden tener hasta 250 caracteres.' : ''}
                                            value={currentPublicClient?.senas || ''}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setCurrentPublicClient(curr => curr ? { ...curr, senas: value } : null);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            margin="dense"
                                            label="Nota"
                                            type="text"
                                            fullWidth
                                            multiline
                                            rows={2}
                                            error={!!currentPublicClient?.nota && !validateSenasNota(currentPublicClient.nota)} // Validación de nota
                                            helperText={!!currentPublicClient?.nota && !validateSenasNota(currentPublicClient.nota) ? 'La nota puede tener hasta 250 caracteres.' : ''}
                                            value={currentPublicClient?.nota || ''}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setCurrentPublicClient(curr => curr ? { ...curr, nota: value } : null);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Enlace de Google Maps"
                                            value={currentPublicClient?.googleMapsLink || ''}
                                            onChange={(e) => {
                                                const newLink = e.target.value;
                                                handlePublicInputChange('googleMapsLink', newLink);
                                                updateLocationFromLink(newLink); // Actualiza la ubicación en el mapa
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => window.open(currentPublicClient?.googleMapsLink, '_blank')}
                                                            disabled={!currentPublicClient?.googleMapsLink}
                                                            edge="end"
                                                        >
                                                            <img src={`${process.env.PUBLIC_URL}/images/maps.png`} alt="Maps" style={{ width: '35px', height: '35px' }} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            disabled={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Enlace de Waze"
                                            value={currentPublicClient?.wazeLink || ''}
                                            onChange={(e) => handlePublicInputChange('wazeLink', e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => window.open(currentPublicClient?.wazeLink, '_blank')}
                                                            disabled={!currentPublicClient?.wazeLink}
                                                            edge="end"
                                                        >
                                                            <img src={`${process.env.PUBLIC_URL}/images/waze.png`} alt="Waze" style={{ width: '40px', height: '40px' }} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            disabled={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h3 style={{ textAlign: 'center', backgroundColor: 'rgb(135, 206, 235)', color: 'white', padding: '10px', borderRadius: '5px' }}>
                                            MASCOTAS E IMAGEN
                                        </h3>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ height: 'calc(100% - 16px)', mb: 2, position: 'relative' }}>
                                    <MapContainer
                                        center={[9.9281, -84.0907]}
                                        zoom={10}
                                        style={{
                                            height: '511px',
                                            width: '100%',
                                            borderRadius: '10px',
                                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                                        }}
                                    >
                                        <TileLayer
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        />
                                        <MapEventHandler onClick={handleMapClick} />
                                        {selectedLocation && (
                                            <Marker position={selectedLocation}>
                                                <Popup>
                                                    <div style={{ textAlign: 'center', padding: '10px', backgroundColor: '#f0f8ff', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0,0,0,0.2)' }}>
                                                        <strong style={{ color: '#333', fontWeight: 'bold' }}>Ubicación seleccionada</strong>
                                                        <br />
                                                        <a href={`https://www.google.com/maps/search/?api=1&query=${selectedLocation.lat},${selectedLocation.lng}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#007bff', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5px', transition: 'background-color 0.3s', padding: '5px', borderRadius: '4px' }} onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e0f7fa'} onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}>
                                                            <img src={`${process.env.PUBLIC_URL}/images/maps.png`} alt="Maps" style={{ width: '35px', height: '35px', marginRight: '5px' }} />
                                                            Google Maps
                                                        </a>
                                                        <a href={`https://waze.com/ul?q=${selectedLocation.lat},${selectedLocation.lng}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#007bff', display: 'flex', alignItems: 'center', justifyContent: 'center', transition: 'background-color 0.3s', padding: '5px', borderRadius: '4px' }} onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e0f7fa'} onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}>
                                                            <img src={`${process.env.PUBLIC_URL}/images/waze.png`} alt="Waze" style={{ width: '40px', height: '40px', marginRight: '5px' }} />
                                                            Waze
                                                        </a>
                                                    </div>
                                                </Popup>
                                            </Marker>
                                        )}
                                    </MapContainer>
                                    <Box sx={{
                                        position: 'absolute',
                                        top: 10,
                                        left: 10,
                                        backgroundColor: 'rgba(255,255,255,0.8)',
                                        padding: '5px 10px',
                                        borderRadius: '20px',
                                        fontSize: '0.8rem',
                                        fontWeight: 'bold',
                                        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                                        zIndex: 1000, // Asegúrate de que este valor sea mayor que el del mapa
                                        marginLeft: '57px',
                                        display: 'flex', // Añadido para alinear el icono y el texto
                                        alignItems: 'center', // Centrar verticalmente el contenido
                                    }}>
                                        Selecciona la ubicación del cliente
                                        <IconButton
                                            onClick={() => {
                                                const mapContainer = document.querySelector('.leaflet-container') as FullscreenHTMLElement; // Aseguramos que sea un FullscreenHTMLElement
                                                if (mapContainer) {
                                                    if (mapContainer.requestFullscreen) {
                                                        mapContainer.requestFullscreen();
                                                    } else if (mapContainer.mozRequestFullScreen) { // Firefox
                                                        mapContainer.mozRequestFullScreen();
                                                    } else if (mapContainer.webkitRequestFullscreen) { // Chrome, Safari y Opera
                                                        mapContainer.webkitRequestFullscreen();
                                                    } else if (mapContainer.msRequestFullscreen) { // IE/Edge
                                                        mapContainer.msRequestFullscreen();
                                                    }
                                                }
                                            }}
                                            sx={{
                                                backgroundColor: 'rgba(135, 206, 235, 0.8)',
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(135, 206, 235, 1)',
                                                },
                                                padding: '8px', // Reducido para hacerlo más pequeño
                                                borderRadius: '4px', // Bordes redondeados más pequeños
                                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Sombra para destacar
                                                marginLeft: '5px', // Espacio entre el texto y el botón
                                            }}
                                        >
                                            <FullscreenIcon style={{ fontSize: '0.8rem' }} /> {/* Icono más pequeño */}
                                        </IconButton>
                                    </Box>
                                    <style>
                                        {`
            .leaflet-bottom.leaflet-right {
                display: none; /* Oculta la atribución de Leaflet */
            }
        `}
                                    </style>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}> {/* Flex wrap para que las cartas se ajusten en la fila */}

                        {['cant_gato', 'cant_perro_mini', 'cant_perro_pequeno', 'cant_perro_mediano', 'cant_perro_grande', 'cant_perro_gigante'].map((field) => (
                            <Grid item xs={12} sm={6} md={4} key={field}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                        border: '1px solid #87CEEB',
                                        borderRadius: 2,
                                        transition: 'transform 0.3s ease',
                                        padding: 1,
                                        height: '200px', // Altura fija para todas las cartas
                                        width: '100%', // Ancho fijo para todas las cartas
                                        position: 'relative', // Para el hover
                                    }}
                                >
                                    <Box display="flex" flexDirection="column" alignItems="center">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/${field}.png`}
                                            alt={publicColumnNames[field as keyof PublicClient]}
                                            style={{
                                                width: '70px',
                                                height: '70px',
                                                transition: 'transform 0.3s ease',
                                            }}
                                            className="image-hover"
                                        />
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{publicColumnNames[field as keyof PublicClient]?.split(' ')[0]}</Typography>
                                        <Typography variant="body2">{publicColumnNames[field as keyof PublicClient]?.split(' ')[1]}</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: 1 }}>
                                        <IconButton
                                            onClick={() => handleQuantityChangePublic(field, false)}
                                            sx={{ color: '#87CEEB', width: '30px', height: '30px' }} // Ancho y alto ajustados
                                        >
                                            <RemoveIcon fontSize="small" />
                                        </IconButton>
                                        <TextField
                                            value={currentPublicClient?.[field as keyof PublicClient] || '0'}
                                            onChange={(e) => setCurrentPublicClient((curr: PublicClient | null) => curr ? { ...curr, [field]: e.target.value } : null)}
                                            inputProps={{
                                                style: { textAlign: 'center' },
                                                min: 0,
                                            }}
                                            sx={{
                                                width: '50px',
                                                '& .MuiInputBase-input': {
                                                    padding: '5px',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: 'transparent',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: 'transparent',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'transparent',
                                                    },
                                                },
                                            }}
                                        />
                                        <IconButton
                                            onClick={() => handleQuantityChangePublic(field, true)}
                                            sx={{ color: '#87CEEB', width: '30px', height: '30px' }} // Ancho y alto ajustados
                                        >
                                            <AddIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                        <Box
                            sx={{
                                width: '100%', // Ancho completo para el contenedor
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center', // Centrar el contenido
                                marginTop: 2,
                            }}
                        >
                            <Box sx={{
                                width: '100%', // Ancho completo
                                height: '200px', // Altura fija
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '2px dashed #87CEEB',
                                borderRadius: 2,
                                position: 'relative',
                                overflow: 'hidden',
                                '&:hover': {
                                    backgroundColor: 'rgba(135, 206, 235, 0.5)', // Cambia el color de fondo al pasar el mouse
                                },
                            }}>
                                {currentPublicClient?.imageUrl ? (
                                    <img
                                        src={currentPublicClient.imageUrl}
                                        alt="Client"
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                            borderRadius: '8px', // Marco bonito
                                            border: '2px solid #87CEEB', // Marco azul
                                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Sombra para destacar
                                        }}
                                    />
                                ) :
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'absolute', // Cambiado a absolute para centrar en el cuadrado
                                        top: '50%', // Centrar verticalmente
                                        left: '50%', // Centrar horizontalmente
                                        transform: 'translate(-50%, -50%)', // Ajustar para centrar
                                        transition: 'opacity 0.3s ease', // Transición suave
                                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Fondo semi-transparente
                                        borderRadius: '8px', // Bordes redondeados
                                        padding: '10px', // Espaciado interno

                                    }}>
                                        <CloudUploadIcon sx={{ fontSize: '48px', color: '#87CEEB' }} /> {/* Icono grande */}
                                        <Typography variant="body1" color="textSecondary" sx={{ fontWeight: 'bold' }}>SUBIR IMAGEN</Typography> {/* Texto en negrita */}
                                    </Box>
                                }
                                <label htmlFor="raised-button-file" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, cursor: 'pointer', opacity: 0 }} />
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    type="file"
                                    onChange={handleImageUploadPublic}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <DialogActions sx={{ justifyContent: 'center', px: 3, py: 2 }}>
                    <Button
                        onClick={(handleCreateClient)}
                        variant="contained"
                        disabled={!isFormValid()}
                        startIcon={<EmojiEmotionsIcon sx={{ transition: 'transform 0.3s', animation: isFormValid() ? 'move 1s infinite alternate' : 'none' }} />} // Movimiento continuo si el botón está habilitado
                        endIcon={<EmojiEmotionsIcon sx={{ transition: 'transform 0.3s', animation: isFormValid() ? 'move 1s infinite alternate' : 'none' }} />} // Movimiento continuo si el botón está habilitado
                        sx={{
                            backgroundColor: 'rgb(135, 206, 235)', // Color actualizado
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#00BFFF',
                                transform: 'scale(1.1)', // Aumentar tamaño del botón
                            },
                            fontWeight: 'bold',
                            px: 4,
                            py: 1,
                            borderRadius: '8px',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        Registro Cliente Ollin
                    </Button>

                </DialogActions>
                <style>
                    {`
        @keyframes move {
            0% { transform: translateY(0); }
            100% { transform: translateY(-5px); }
        }
    `}
                </style>
                {/* Error Snackbar */}
                <Snackbar open={!!error} autoHideDuration={6000} onClose={handleClearError}>
                    <Alert onClose={handleClearError} severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>
                {/* Success Snackbar */}
                <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage(null)}>
                    <Alert onClose={() => setSuccessMessage(null)} severity="success" sx={{ width: '100%', backgroundColor: 'green', color: 'white' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
            </Box>
            <Dialog
                open={openConfirmationDialog}
                onClose={() => setOpenConfirmationDialog(false)}
                fullWidth
                maxWidth="sm"
                BackdropProps={{
                    sx: {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    },
                }}
                PaperProps={{
                    sx: {
                        borderRadius: '8px',
                    },
                }}
            >
                <DialogTitle sx={{
                    backgroundColor: '#87CEEB',
                    color: 'white',
                    fontWeight: 'bold'
                }}>
                    Confirmar Creación de Cliente
                </DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4, paddingTop: '29px !important' }}>
                    <Box sx={{
                        backgroundColor: 'green',
                        borderRadius: '50%',
                        padding: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 2
                    }}>
                        <CreateIcon sx={{ color: 'white', fontSize: 40 }} />
                    </Box>
                    <Typography variant="h6" align="center" sx={{ color: '#333', fontWeight: 'bold' }}>
                        ¿Está seguro de que desea crear este cliente como definitivo?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{
                    justifyContent: 'center',
                    px: 3,
                    py: 2,
                    backgroundColor: 'rgba(135, 206, 235, 0.1)'
                }}>
                    <Button onClick={() => setOpenConfirmationDialog(false)}
                        variant="outlined"
                        sx={{
                            color: '#87CEEB',
                            borderColor: '#87CEEB',
                            '&:hover': {
                                backgroundColor: 'rgba(135, 206, 235, 0.1)',
                                borderColor: '#5CACEE',
                            },
                            fontWeight: 'bold',
                            px: 4,
                            py: 1,
                            mr: 2,
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button onClick={confirmCreateClient} // Llama a la función para crear el cliente
                        variant="contained"
                        sx={{
                            backgroundColor: '#87CEEB',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#5CACEE',
                            },
                            fontWeight: 'bold',
                            px: 4,
                            py: 1,
                        }}>
                        Crear Cliente
                    </Button>
                </DialogActions>
            </Dialog>
        </Container >
    );
};


export default PublicClientDialog;