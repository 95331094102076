import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebase';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard';
import DriverDashboard from './components/DriverDashboard';
import PublicClientInterface from './components/PublicClientInterface'; // Importa el nuevo componente
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CircularProgress, Box, Snackbar, Alert } from '@mui/material';
import { collection, addDoc, updateDoc, doc, getDoc, setDoc } from 'firebase/firestore'; // Asegúrate de importar estas funciones
import { PublicClient } from './models';

const theme = createTheme();

const App: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openPublicClientInterface, setOpenPublicClientInterface] = useState(false);
  const [currentPublicClient, setCurrentPublicClient] = useState<any>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
        }
      } else {
        setUserRole(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleClearError = () => {
    setError(null);
  };

  const handleSavePublicClient = async (client: PublicClient) => { // Accepts a client argument
    if (client) {
        try {
            // Agregar un nuevo cliente público a la colección
            const docRef = await addDoc(collection(db, 'publicClients'), client); // Use the passed client
            console.log("Documento escrito con ID: ", docRef.id);
            setOpenPublicClientInterface(false); // Cierra el diálogo
        } catch (error) {
            console.error("Error al agregar el documento: ", error);
            setError("Error al guardar el cliente público."); // Manejo de errores
        }
    }
};

  const onUpdatePublicClient = async (client: PublicClient) => {
    if (client.id) {
      try {
        const clientRef = doc(db, 'publicClients', client.id); // Referencia al cliente en Firestore
        const clientData = { ...client }; // Copia de seguridad de los datos del cliente
        delete clientData.id; // Elimina el ID del cliente de los datos a actualizar
        await updateDoc(clientRef, clientData); // Actualiza el cliente en Firestore
        console.log("Cliente público actualizado con ID: ", client.id);
      } catch (error) {
        console.error("Error al actualizar el cliente público:", error);
        setError("Error al actualizar el cliente público."); // Manejo de errores
      }
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/login" element={user ? <Navigate to="/admin-dashboard" /> : <Login />} />
          <Route path="/admin-dashboard" element={user && userRole === 'admin' ? <AdminDashboard /> : <Navigate to="/login" />} />
          <Route path="/driver-dashboard" element={user && userRole === 'driver' ? <DriverDashboard /> : <Navigate to="/login" />} />
          <Route path="/public-client" element={
            <PublicClientInterface
              open={openPublicClientInterface}
              onClose={() => setOpenPublicClientInterface(false)}
              handleSavePublicClient={handleSavePublicClient}
            />}
          />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </Router>

      {/* Error Snackbar */}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleClearError}>
        <Alert onClose={handleClearError} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default App;