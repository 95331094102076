import React, { useState, useEffect, useCallback } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  styled,
  CircularProgress,
  Grid,
  Paper,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EventNoteIcon from '@mui/icons-material/EventNote';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import PeopleIcon from '@mui/icons-material/People';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { signOut } from 'firebase/auth';
import { auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { collection, query, getDocs, addDoc, updateDoc, deleteDoc, doc, serverTimestamp } from 'firebase/firestore';
import { Client, InventoryItem, PublicClient } from '../models';
import RouteGroupsCRUD from './RouteGroupsCRUD';
import ClientList from './ClientList';
import OrdersCalendar from './OrdersCalendar';
import OrdersTable from './OrdersTable';
import InventoryManagement from './InventoryManagement';

const drawerWidth = 250;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    paddingTop: theme.spacing(2),
  },
}));

const Logo = styled('img')({
  width: '150px',
  margin: '0 auto 20px',
  display: 'block',
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'rgba(0, 180, 216, 0.08)',
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      color: '#00b4d8',
    },
  },
}));

const NavItemText = styled(ListItemText)({
  '& .MuiListItemText-primary': {
    fontSize: '0.9rem',
    fontWeight: 500,
    color: '#757575',
  },
});

const DividerText = styled(Typography)({
  padding: '16px 16px 8px',
  color: '#00b4d8',
  fontSize: '0.75rem',
  fontWeight: 'bold',
});

const NavIcon = styled(ListItemIcon)({
  color: '#757575',
  minWidth: '40px',
});

const MainContent = styled(Box)({
  flexGrow: 1,
  padding: '20px',
});

const PageTitle = styled(Typography)({
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: '20px',
  color: '#333',
  borderBottom: '2px solid #00b4d8',
  paddingBottom: '10px',
  marginTop: '29px'
});

const AdminDashboard: React.FC = () => {
  const [selectedSection, setSelectedSection] = useState('home');
  const [clients, setClients] = useState<Client[]>([]);
  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [publicClients, setPublicClients] = useState<PublicClient[]>([]); // Nuevo estado para PublicClient

  const fetchPublicClients = useCallback(async () => {
    try {
      const q = query(collection(db, 'publicClients')); // Cambiado a la colección publicClients
      const querySnapshot = await getDocs(q);
      const publicClientsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as PublicClient)); // Asegúrate de usar PublicClient
      console.log("Clientes públicos obtenidos:", publicClientsList); // Agregar este log
      setPublicClients(publicClientsList); // Actualiza el estado de publicClients
    } catch (error) {
      console.error('Error fetching public clients:', error);
      setError('Error al cargar los clientes públicos');
      toast.error('Error al cargar los clientes públicos');
    }
  }, []);

  const fetchClients = useCallback(async () => {
    try {
      const q = query(collection(db, 'clients'));
      const querySnapshot = await getDocs(q);
      const clientsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Client));
      setClients(clientsList);
    } catch (error) {
      console.error('Error fetching clients:', error);
      setError('Error al cargar los clientes');
      toast.error('Error al cargar los clientes');
    }
  }, []);

  const fetchInventoryItems = useCallback(async () => {
    try {
      const q = query(collection(db, 'inventoryItems'));
      const querySnapshot = await getDocs(q);
      const items = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as InventoryItem));
      setInventoryItems(items);
    } catch (error) {
      console.error('Error fetching inventory items:', error);
      toast.error('Error al cargar los items de inventario');
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([fetchClients(), fetchInventoryItems(), fetchPublicClients()]); // Agregado fetchPublicClients
      setIsLoading(false);
    };
    fetchData();
  }, [fetchClients, fetchInventoryItems, fetchPublicClients]); // Asegúrate de incluir fetchPublicClients en las dependencias

  const handleUpdateInventoryItem = async (updatedItem: InventoryItem) => {
    try {
      const itemRef = doc(db, 'inventoryItems', updatedItem.id);
      await updateDoc(itemRef, {
        ...updatedItem,
        updatedAt: serverTimestamp()
      });
      toast.success('Item actualizado exitosamente');
      fetchInventoryItems(); // Refetch para actualizar la lista
    } catch (error) {
      console.error('Error updating inventory item:', error);
      toast.error('Error al actualizar el item');
    }
  };

  const handleDeleteInventoryItem = async (itemId: string) => {
    if (window.confirm('¿Está seguro de que desea eliminar este item?')) {
      try {
        await deleteDoc(doc(db, 'inventoryItems', itemId));
        toast.success('Item eliminado exitosamente');
        fetchInventoryItems();
      } catch (error) {
        console.error('Error deleting inventory item:', error);
        toast.error('Error al eliminar el item');
      }
    }
  };

  const handleAddInventoryItem = async (newItem: Omit<InventoryItem, 'id'>) => {
    try {
      await addDoc(collection(db, 'inventoryItems'), {
        ...newItem,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
      toast.success('Nuevo item agregado exitosamente');
      fetchInventoryItems(); // Refetch para actualizar la lista
    } catch (error) {
      console.error('Error adding new inventory item:', error);
      toast.error('Error al agregar el nuevo item');
    }
  };
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/login');
      toast.success('Sesión cerrada exitosamente');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
      toast.error('Error al cerrar sesión');
    }
  };

  const handlePublicClientSubmit = async (client: PublicClient) => {
    try {
      await addDoc(collection(db, 'publicClients'), client); // Cambiado a la colección publicClients
      toast.success('Cliente público agregado exitosamente');
      fetchPublicClients(); // Asegúrate de tener una función para obtener los clientes públicos
    } catch (error) {
      console.error('Error adding public client:', error);
      toast.error('Error al agregar cliente público');
    }
  };

  const handlePublicClientUpdate = async (client: PublicClient) => {
    try {
      if (client.id) {
        await updateDoc(doc(db, 'publicClients', client.id), { ...client }); // Cambiado a la colección publicClients
        toast.success('Cliente público actualizado exitosamente');
        fetchPublicClients(); // Asegúrate de tener una función para obtener los clientes públicos
      }
    } catch (error) {
      console.error('Error updating public client:', error);
      toast.error('Error al actualizar cliente público');
    }
  };

  const handlePublicClientDelete = async (clientId: string) => {
    try {
      await deleteDoc(doc(db, 'publicClients', clientId)); // Eliminar de la colección publicClients
      toast.success('Cliente público eliminado exitosamente');
      fetchPublicClients(); // Refrescar la lista de clientes públicos
    } catch (error) {
      console.error('Error deleting public client:', error);
      toast.error('Error al eliminar cliente público');
    }
  };

  const handleClientSubmit = async (client: Client) => {
    try {
      await addDoc(collection(db, 'clients'), client);
      toast.success('Cliente agregado exitosamente');
      fetchClients();
    } catch (error) {
      console.error('Error adding client:', error);
      toast.error('Error al agregar cliente');
    }
  };

  const handleClientUpdate = async (client: Client) => {
    try {
      if (client.id) {
        await updateDoc(doc(db, 'clients', client.id), { ...client });
        toast.success('Cliente actualizado exitosamente');
        fetchClients();
      }
    } catch (error) {
      console.error('Error updating client:', error);
      toast.error('Error al actualizar cliente');
    }
  };

  const handleClientDelete = async (clientId: string) => {
    try {
      await deleteDoc(doc(db, 'clients', clientId));
      toast.success('Cliente eliminado exitosamente');
      fetchClients();
    } catch (error) {
      console.error('Error deleting client:', error);
      toast.error('Error al eliminar cliente');
    }
  };

  const renderHome = () => (
    <>
      <PageTitle>Inicio</PageTitle>
      <Typography>Bienvenido al panel de administración</Typography>
    </>
  );

  const renderActiveRoute = () => (
    <>
      <PageTitle>Ruta Activa</PageTitle>
      <Typography>Aquí se mostrará la información de la ruta activa</Typography>
    </>
  );

  const renderCalendario = () => (
    <>
      <PageTitle>Calendario</PageTitle>
      <OrdersCalendar />
    </>
  );

  const renderOrders = () => (
    <>
      <PageTitle>Pedidos</PageTitle>
      <OrdersTable />
    </>
  );

  const renderRoutes = () => (
    <>
      <PageTitle>Rutas</PageTitle>
      <RouteGroupsCRUD />
    </>
  );

  const renderInventory = () => (
    <>
      <PageTitle>Inventario</PageTitle>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <InventoryManagement
          inventoryItems={inventoryItems}
          onUpdateItem={handleUpdateInventoryItem}
          onDeleteItem={handleDeleteInventoryItem}
          onAddItem={handleAddInventoryItem}
        />
      )}
    </>
  );

  const renderClients = () => (
    <>
      <PageTitle>Clientes</PageTitle>
      <ClientList
        clients={clients}
        publicClients={publicClients} // Pasar publicClients
        onUpdate={handleClientUpdate}
        onDelete={handleClientDelete}
        onAddNew={handleClientSubmit}
        onUpdatePublicClient={handlePublicClientUpdate} // Pasar la función de actualización
        onAddNewPublicClient={handlePublicClientSubmit} // Pasar la función de adición
        onDeletePublicClient={handlePublicClientDelete} // Pasar la función de eliminación
      />
    </>
  );

  const renderDrivers = () => (
    <>
      <PageTitle>Transportistas</PageTitle>
      <Typography>Aquí se mostrará la lista de transportistas</Typography>
    </>
  );

  const renderContent = () => {
    switch (selectedSection) {
      case 'calendario':
        return renderCalendario();
      case 'home':
        return renderCalendario();
      case 'active-route':
        return renderActiveRoute();
      case 'orders':
        return renderOrders();
      case 'routes':
        return renderRoutes();
      case 'inventory':
        return renderInventory();
      case 'clients':
        return renderClients();
      case 'drivers':
        return renderDrivers();
      default:
        return renderHome();
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <StyledDrawer variant="permanent" anchor="left">
        <Logo src="https://ollinadmin.web.app/static/media/ollin_Logo.e2ef52aceaf443447897.png" alt="Ollin Logo" />
        <List>
          <StyledListItem onClick={() => setSelectedSection('home')}>
            <NavIcon><HomeIcon /></NavIcon>
            <NavItemText primary="Inicio" />
          </StyledListItem>
          <StyledListItem onClick={() => setSelectedSection('active-route')}>
            <NavIcon><LocalShippingIcon /></NavIcon>
            <NavItemText primary="Ruta Activa" />
          </StyledListItem>

          <DividerText>Gestion de Rutas</DividerText>

          <StyledListItem onClick={() => setSelectedSection('orders')}>
            <NavIcon><LocalMallIcon /></NavIcon>
            <NavItemText primary="Pedidos" />
          </StyledListItem>
          <StyledListItem onClick={() => setSelectedSection('routes')}>
            <NavIcon><EventNoteIcon /></NavIcon>
            <NavItemText primary="Rutas" />
          </StyledListItem>
          <StyledListItem onClick={() => setSelectedSection('calendario')}>
            <NavIcon><ShoppingCartIcon /></NavIcon>
            <NavItemText primary="Calendario" />
          </StyledListItem>

          <DividerText>Mantenimiento</DividerText>

          <StyledListItem onClick={() => setSelectedSection('inventory')}>
            <NavIcon><Inventory2Icon /></NavIcon>
            <NavItemText primary="Inventario" />
          </StyledListItem>
          <StyledListItem onClick={() => setSelectedSection('clients')}>
            <NavIcon><PeopleIcon /></NavIcon>
            <NavItemText primary="Clientes" />
          </StyledListItem>
          <StyledListItem onClick={() => setSelectedSection('drivers')}>
            <NavIcon><DriveEtaIcon /></NavIcon>
            <NavItemText primary="Transportistas" />
          </StyledListItem>

          <StyledListItem onClick={handleSignOut}>
            <NavIcon><ExitToAppIcon /></NavIcon>
            <NavItemText primary="Cerrar Sesión" />
          </StyledListItem>
        </List>
      </StyledDrawer>
      <MainContent>
        {renderContent()}
      </MainContent>
    </Box>
  );
};

export default AdminDashboard;