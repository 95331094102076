import React, { useState, useEffect, useMemo } from 'react';
import {
  collection,
  query,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  writeBatch,
  Timestamp,
  getDoc,
} from 'firebase/firestore';
import { db } from '../firebase';
import { RouteGroup, Route, Client, Order, InventoryItem } from '../models';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Paper,
  CircularProgress,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormControlLabel,
  Tooltip,
  FormGroup,
  TablePagination,
  TableSortLabel,
  Card,
  CardContent,
  CardActions,
  Avatar,
  SvgIcon,
  SvgIconProps,
  Chip,
  Switch,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  CalendarToday,
  Today,
  PendingActions,
  CheckCircle,
  Cancel,
  FilterList as FilterListIcon,
  ViewColumn as ViewColumnIcon,
  Search as SearchIcon,
  Print as PrintIcon,
  GetApp as GetAppIcon,
  FormatListBulleted,
} from '@mui/icons-material';
import OrderCreationModal from './OrderCreationModal';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

interface OrderCreationModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (order: Order) => Promise<void>;
  inventoryItems: InventoryItem[];
  initialOrder?: Order;
}

const columnNames = {
  name: 'Nombre',
  routeType: 'Tipo de Ruta',
  dayOfWeek: 'Día de la Semana',
  weekOfMonth: 'Semana del Mes',
  createdAt: 'Fecha de Inicio',
  nextDelivery: 'Siguiente Entrega'
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#00b4d8',
    },
    '&:hover fieldset': {
      borderColor: '#00b4d8',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00b4d8',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#00b4d8',
  },
}));

const InfoSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: '#f5f5f5',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  color: '#00b4d8',
}));

const IconWrapper = styled('span')(({ theme }) => ({
  marginRight: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${theme.breakpoints.down('sm')}`]: {
    display: 'none',
  },
}));

// Definir los iconos personalizados
const OllinIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2M1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2" />
  </SvgIcon>
);

const RecoleccionIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 512 512">
    <path d="M184.561 261.903c3.232 13.997-12.123 24.635-24.068 17.168l-40.736-25.455-50.867 81.402C55.606 356.273 70.96 384 96.012 384H148c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12H96.115c-75.334 0-121.302-83.048-81.408-146.88l50.822-81.388-40.725-25.448c-12.081-7.547-8.966-25.961 4.879-29.158l110.237-25.45c8.611-1.988 17.201 3.381 19.189 11.99l25.452 110.237zm98.561-182.915l41.289 66.076-40.74 25.457c-12.051 7.528-9 25.953 4.879 29.158l110.237 25.45c8.672 1.999 17.215-3.438 19.189-11.99l25.45-110.237c3.197-13.844-11.99-24.719-24.068-17.168l-40.687 25.424-41.263-66.082c-37.521-60.033-125.209-60.171-162.816 0l-17.963 28.766c-3.51 5.62-1.8 13.021 3.82 16.533l33.919 21.195c5.62 3.512 13.024 1.803 16.536-3.817l17.961-28.743c12.712-20.341 41.973-19.676 54.257-.022zM497.288 301.12l-27.515-44.065c-3.511-5.623-10.916-7.334-16.538-3.821l-33.861 21.159c-5.62 3.512-7.33 10.915-3.818 16.536l27.564 44.112c13.257 21.211-2.057 48.96-27.136 48.96H320V336.02c0-14.213-17.242-21.383-27.313-11.313l-80 79.981c-6.249 6.248-6.249 16.379 0 22.627l80 79.989C302.689 517.308 320 510.3 320 495.989V448h95.88c75.274 0 121.335-82.997 81.408-146.88z" />
  </SvgIcon>
);

const RouteGroupsCRUD: React.FC = () => {
  const [routeGroups, setRouteGroups] = useState<RouteGroup[]>([]);
  const [open, setOpen] = useState(false);
  const [currentRouteGroup, setCurrentRouteGroup] = useState<RouteGroup | null>(null);
  const [clients, setClients] = useState<Client[]>([]);
  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [currentRoute, setCurrentRoute] = useState<Route | null>(null);
  const [loading, setLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [columnSelectionDialogOpen, setColumnSelectionDialogOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState<Set<string>>(new Set([
    'name', 'routeType', 'dayOfWeek', 'weekOfMonth', 'createdAt', 'nextDelivery'
  ]));
  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof RouteGroup>('name');
  const [tabValue, setTabValue] = useState(0);
  const [selectedRouteTypes, setSelectedRouteTypes] = useState<string[]>([]); // Añadir este estado

  useEffect(() => {
    fetchRouteGroups();
    fetchClients();
    fetchInventoryItems();
  }, []);

  const fetchRouteGroups = async () => {
    setLoading(true);
    try {
      const q = query(collection(db, 'routeGroups'));
      const querySnapshot = await getDocs(q);
      const groups: RouteGroup[] = [];

      for (const docRef of querySnapshot.docs) {
        const groupData = docRef.data();
        const routesSnapshot = await getDocs(collection(db, 'routeGroups', docRef.id, 'routes'));
        const routes: Route[] = routesSnapshot.docs.map(routeDoc => ({
          id: routeDoc.id,
          ...routeDoc.data()
        } as Route));

        groups.push({
          id: docRef.id,
          name: groupData.name,
          dayOfWeek: groupData.dayOfWeek,
          weekOfMonth: groupData.weekOfMonth,
          createdAt: groupData.createdAt.toDate(),
          routes: routes,
          routeType: groupData.routeType || 'monthly'
        });
      }

      setRouteGroups(groups);
    } catch (error) {
      console.error("Error fetching route groups: ", error);
      toast.error('Error fetching route groups');
    } finally {
      setLoading(false);
    }
  };

  const fetchClients = async () => {
    try {
      const q = query(collection(db, 'clients'));
      const querySnapshot = await getDocs(q);
      setClients(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Client)));
    } catch (error) {
      console.error("Error fetching clients: ", error);
      toast.error('Error fetching clients');
    }
  };

  const fetchInventoryItems = async () => {
    try {
      const q = query(collection(db, 'inventoryItems'));
      const querySnapshot = await getDocs(q);
      setInventoryItems(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as InventoryItem)));
    } catch (error) {
      console.error("Error fetching inventory items: ", error);
      toast.error('Error fetching inventory items');
    }
  };

  const handleOpen = (routeGroup: RouteGroup | null = null) => {
    setCurrentRouteGroup(routeGroup || {
      name: '',
      dayOfWeek: 1,
      weekOfMonth: 1,
      createdAt: new Date(),
      routes: [],
      routeType: 'weekly'
    } as RouteGroup);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentRouteGroup(null);
  };

  const handleRouteTypeChange = (value: 'weekly' | 'biweekly' | 'monthly' | 'bimonthly') => {
    if (!currentRouteGroup) return;

    let updatedGroup: RouteGroup = {
      ...currentRouteGroup,
      routeType: value,
      dayOfWeek: value === 'monthly' || value === 'bimonthly' ? (currentRouteGroup.dayOfWeek || 1) : 0,
      weekOfMonth: value === 'monthly' || value === 'bimonthly' ? (currentRouteGroup.weekOfMonth || 1) : 0,
      createdAt: value === 'monthly' || value === 'bimonthly' ? new Date() : (currentRouteGroup.createdAt || new Date()),
    };
    setCurrentRouteGroup(updatedGroup);
  };

  const handleCreatedAtChange = (date: Date | null) => {
    if (!currentRouteGroup || !date) return;
    setCurrentRouteGroup({
      ...currentRouteGroup,
      createdAt: date
    });
  };

  const handleSave = async () => {
    if (!currentRouteGroup || !isFormValid()) return;

    try {
      const batch = writeBatch(db);

      const routeGroupData = {
        name: currentRouteGroup.name,
        routeType: currentRouteGroup.routeType,
        dayOfWeek: currentRouteGroup.dayOfWeek,
        weekOfMonth: currentRouteGroup.weekOfMonth,
        createdAt: Timestamp.fromDate(currentRouteGroup.createdAt || new Date())
      };

      if (currentRouteGroup.id) {
        const routeGroupRef = doc(db, 'routeGroups', currentRouteGroup.id);
        batch.update(routeGroupRef, routeGroupData);

        const routesRef = collection(routeGroupRef, 'routes');
        const existingRoutes = await getDocs(routesRef);

        existingRoutes.forEach((doc) => {
          batch.delete(doc.ref);
        });

        currentRouteGroup.routes.forEach((route) => {
          const newRouteRef = doc(routesRef);
          batch.set(newRouteRef, {
            clientId: route.clientId,
            status: route.status,
            order: route.order
          });
        });
      } else {
        const newRouteGroupRef = doc(collection(db, 'routeGroups'));
        batch.set(newRouteGroupRef, routeGroupData);

        const routesRef = collection(newRouteGroupRef, 'routes');
        currentRouteGroup.routes.forEach((route) => {
          const newRouteRef = doc(routesRef);
          batch.set(newRouteRef, {
            clientId: route.clientId,
            status: route.status,
            order: route.order
          });
        });
      }

      await batch.commit();
      toast.success(currentRouteGroup.id ? 'Grupo de rutas actualizado exitosamente' : 'Nuevo grupo de rutas creado exitosamente');
      handleClose();
      fetchRouteGroups();

    } catch (error) {
      console.error("Error saving route group: ", error);
      toast.error('Error al guardar el grupo de rutas');
    }
  };

  const handleDelete = async (id: string) => {
    setGroupToDelete(id);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (!groupToDelete) return;

    try {
      const batch = writeBatch(db);
      const routeGroupRef = doc(db, 'routeGroups', groupToDelete);
      const routesRef = collection(routeGroupRef, 'routes');
      const routesSnapshot = await getDocs(routesRef);

      routesSnapshot.docs.forEach((routeDoc) => {
        batch.delete(routeDoc.ref);
      });

      batch.delete(routeGroupRef);

      await batch.commit();
      fetchRouteGroups();
      toast.success('Grupo de rutas eliminado exitosamente');
    } catch (error) {
      console.error("Error al eliminar el grupo de rutas: ", error);
      toast.error('Error al eliminar el grupo de rutas');
    } finally {
      setDeleteDialogOpen(false);
      setGroupToDelete(null);
    }
  };

  const removeRoute = (index: number) => {
    if (!currentRouteGroup) return;
    const updatedRoutes = currentRouteGroup.routes.filter((_, i) => i !== index);
    setCurrentRouteGroup({ ...currentRouteGroup, routes: updatedRoutes });
    toast.info('Ruta eliminada');
  };

  const handleRouteChange = (index: number, field: keyof Route, value: any) => {
    if (!currentRouteGroup) return;
    const updatedRoutes = [...currentRouteGroup.routes];
    updatedRoutes[index] = { ...updatedRoutes[index], [field]: value };
    setCurrentRouteGroup({ ...currentRouteGroup, routes: updatedRoutes });
  };

  const addRoute = () => {
    if (!currentRouteGroup) return;
    const newRoute: Route = {
      clientId: '',
      status: 'pending',
      order: { items: [] }
    };
    setCurrentRouteGroup({
      ...currentRouteGroup,
      routes: [...currentRouteGroup.routes, newRoute]
    });
  };

  const handleCreateOrEditOrder = (routeGroup: RouteGroup, route: Route) => {
    setCurrentRouteGroup(routeGroup);
    setCurrentRoute(route);
    setIsOrderModalOpen(true);
  };

  const handleOrderCreated = async (order: Order) => {
    if (currentRouteGroup && currentRoute) {
      try {
        const updatedRoute: Route = {
          ...currentRoute,
          order: order,
          status: 'pending'
        };

        const updatedRoutes = currentRouteGroup.routes.map(r =>
          r === currentRoute ? updatedRoute : r
        );

        setCurrentRouteGroup({
          ...currentRouteGroup,
          routes: updatedRoutes
        });

        const updatedInventoryItems = [...inventoryItems];
        for (const item of order.items) {
          const inventoryItem = updatedInventoryItems.find(i => i.id === item.inventoryItemId);
          if (inventoryItem) {
            inventoryItem.quantity -= item.quantity;
          }
        }
        setInventoryItems(updatedInventoryItems);

        setIsOrderModalOpen(false);
        setCurrentRoute(null);
        toast.success('Orden creada exitosamente');
      } catch (error) {
        console.error("Error updating route group with new order: ", error);
        toast.error('Error al actualizar el grupo de rutas con la nueva orden');
      }
    }
  };

  const isFormValid = () => {
    if (!currentRouteGroup) return false;
    return (
      currentRouteGroup.name.trim() !== '' &&
      currentRouteGroup.routes.length > 0 &&
      currentRouteGroup.routes.every(route =>
        route.clientId && (
          (route.order && route.order.items && route.order.items.some(item => item.quantity > 0)) ||
          route.status === 'pending'
        )
      )
    );
  };

  const getDayOfWeekName = (day: number) => {
    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    return days[day];
  };

  const calculateNextDeliveryDate = (group: RouteGroup): Date => {
    const today = new Date();
    let nextDelivery = new Date(group.createdAt);

    switch (group.routeType) {
      case 'weekly':
        while (nextDelivery <= today) {
          nextDelivery.setDate(nextDelivery.getDate() + 7);
        }
        break;
      case 'biweekly':
        while (nextDelivery <= today) {
          nextDelivery.setDate(nextDelivery.getDate() + 14);
        }
        break;
      case 'monthly':
      case 'bimonthly':
        while (nextDelivery <= today) {
          nextDelivery.setMonth(nextDelivery.getMonth() + (group.routeType === 'monthly' ? 1 : 2));
          nextDelivery.setDate(1);
          while (nextDelivery.getDay() !== group.dayOfWeek) {
            nextDelivery.setDate(nextDelivery.getDate() + 1);
          }
          nextDelivery.setDate(nextDelivery.getDate() + (group.weekOfMonth - 1) * 7);
        }
        break;
    }

    return nextDelivery;
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterDialogOpen = () => {
    setFilterDialogOpen(true);
  };

  const handleFilterDialogClose = () => {
    setFilterDialogOpen(false);
  };

  const handleColumnSelectionDialogOpen = () => {
    setColumnSelectionDialogOpen(true);
  };

  const handleColumnSelectionDialogClose = () => {
    setColumnSelectionDialogOpen(false);
  };

  const handleColumnToggle = (column: string) => {
    setVisibleColumns(prevColumns => {
      const newColumns = new Set(prevColumns);
      if (newColumns.has(column)) {
        newColumns.delete(column);
      } else {
        newColumns.add(column);
      }
      return newColumns;
    });
  };

  const handleFilterChange = (field: string, value: any) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [field]: value
    }));
    // Actualiza las selecciones de tipo de ruta
    if (field === 'routeType') {
      setSelectedRouteTypes(value);
    }
  };

  const applyFilters = () => {
    // Apply filters logic here
    handleFilterDialogClose();
  };

  const filteredRouteGroups = useMemo(() => {
    const sortedGroups = [...routeGroups].sort((a, b) => {
      let aValue: any;
      let bValue: any;

      // Manejo de la columna "Siguiente Entrega"
      if (orderBy === 'nextDelivery' as keyof RouteGroup) {
        aValue = calculateNextDeliveryDate(a).getTime(); // Convertir a timestamp
        bValue = calculateNextDeliveryDate(b).getTime(); // Convertir a timestamp
      } else {
        aValue = a[orderBy as keyof RouteGroup];
        bValue = b[orderBy as keyof RouteGroup];
      }

      if (aValue === undefined || bValue === undefined) {
        return 0; // Si alguno es undefined, no se cambia el orden
      }

      if (order === 'desc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0; // Comparación para orden ascendente
      } else {
        return aValue > bValue ? -1 : aValue < bValue ? 1 : 0; // Comparación para orden descendente
      }
    });

    return sortedGroups.filter(group => {
      const matchesRouteType = selectedRouteTypes.length === 0 || selectedRouteTypes.includes(group.routeType);
      const matchesSearchTerm = (
        group.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        group.routeType.toLowerCase().includes(searchTerm.toLowerCase()) ||
        getDayOfWeekName(group.dayOfWeek).toLowerCase().includes(searchTerm.toLowerCase()) ||
        group.weekOfMonth.toString().includes(searchTerm) ||
        group.createdAt.toLocaleDateString().includes(searchTerm) ||
        calculateNextDeliveryDate(group).toLocaleDateString().includes(searchTerm)
      );
      return matchesRouteType && matchesSearchTerm; // Filtra por tipo de ruta y término de búsqueda
    });
  }, [routeGroups, selectedRouteTypes, searchTerm, order, orderBy]); // Añadir order y orderBy como dependencias

  const handlePrint = () => {
    window.print();
  };

  const handleDownloadCSV = () => {
    const headers = Array.from(visibleColumns);
    const csvContent = [
      headers.join(','),
      ...filteredRouteGroups.map(group =>
        headers.map(header => {
          if (header === 'nextDelivery') {
            return calculateNextDeliveryDate(group).toLocaleDateString();
          }
          return (group[header as keyof RouteGroup] || '').toString();
        }).join(',')
      )
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'route_groups.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const handleRequestSort = (property: keyof RouteGroup) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ display: 'contents', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            onClick={() => handleOpen()}
            sx={{
              mb: 3, backgroundColor: '#00b4d8',
              '&:hover': {
                backgroundColor: 'rgb(0, 180, 216)',
              },
              fontWeight: 'bold'
            }}
          >
            Agregar Rutas
          </Button>
          {/* <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 2 }}>
            <Tab label="Tabla de Rutas" />
            <Tab label="Lista de Cartas" />
          </Tabs> */}
          <FormControlLabel
            control={
              <Switch
                checked={tabValue === 1}
                onChange={() => setTabValue(tabValue === 0 ? 1 : 0)}
                color="primary"
                sx={{
                  '&.MuiSwitch-root': {
                    '& .MuiSwitch-thumb': {
                      backgroundColor: 'rgb(0, 180, 216) !important',
                    }
                  },
                }}
              />
            }
            label={
              <span style={{ fontWeight: 'bold' }}>
                {tabValue === 0 ? "Tabla de Rutas" : "Lista de Cartas"}
              </span>
            }
            sx={{ mb: 2, marginBottom: '21px' }}
          />
        </Box>

        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={handleSearch}
            InputProps={{
              startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />,
            }}
            sx={{ alignItems: 'left' }}
          />

          <Box sx={{ alignItems: 'right' }}>
            <Tooltip title="Filtrar">
              <IconButton onClick={handleFilterDialogOpen}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Seleccionar columnas">
              <IconButton onClick={handleColumnSelectionDialogOpen}>
                <ViewColumnIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Imprimir">
              <IconButton onClick={handlePrint}>
                <PrintIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Descargar CSV">
              <IconButton onClick={handleDownloadCSV}>
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      {/* Mostrar filtros seleccionados */}
      <Box sx={{ mb: 2, marginTop: '13px' }}>
        {selectedRouteTypes.map((type) => (
          <Chip
            key={type}
            label={type === 'weekly' ? 'Semanal' :
              type === 'biweekly' ? 'Bisemanal' :
                type === 'monthly' ? 'Mensual' : 'Bimensual'}
            onDelete={() => {
              const newSelected = selectedRouteTypes.filter(t => t !== type);
              setSelectedRouteTypes(newSelected);
              handleFilterChange('routeType', newSelected); // Actualiza los filtros
            }}
            sx={{ mr: 1, backgroundColor: 'rgb(0, 180, 216)', color: 'white' }}
          />
        ))}
      </Box>

      {
        tabValue === 0 && (
          // Vista de Tabla
          <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="route groups table">
              <TableHead sx={{ backgroundColor: 'rgb(0, 180, 216)' }}>
                <TableRow>
                  {Array.from(visibleColumns).map((column) => (
                    <TableCell key={column}>
                      <TableSortLabel
                        active={orderBy === column}
                        direction={orderBy === column ? order : 'asc'}
                        onClick={() => handleRequestSort(column as keyof RouteGroup)}
                        sx={{ color: 'white', fontWeight: 'bold' }}
                      >
                        {columnNames[column as keyof typeof columnNames]}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRouteGroups.map((group) => {
                  const nextDelivery = calculateNextDeliveryDate(group);
                  return (
                    <TableRow
                      key={group.id}
                      sx={{
                        '&:hover': {
                          backgroundColor: 'rgba(0, 180, 216, 0.1)',
                          '& .edit-icon': {
                            opacity: 1,
                          },
                        },
                      }}
                    >
                      {visibleColumns.has('name') && <TableCell>{group.name}</TableCell>}
                      {visibleColumns.has('routeType') && (
                        <TableCell>
                          {group.routeType === 'weekly' ? 'Semanal' :
                            group.routeType === 'biweekly' ? 'Bisemanal' :
                              group.routeType === 'monthly' ? 'Mensual' : 'Bimensual'}
                        </TableCell>
                      )}
                      {visibleColumns.has('dayOfWeek') && (
                        <TableCell>
                          {(group.routeType === 'monthly' || group.routeType === 'bimonthly') &&
                            getDayOfWeekName(group.dayOfWeek)}
                        </TableCell>
                      )}
                      {visibleColumns.has('weekOfMonth') && (
                        <TableCell>
                          {(group.routeType === 'monthly' || group.routeType === 'bimonthly') &&
                            group.weekOfMonth}
                        </TableCell>
                      )}
                      {visibleColumns.has('createdAt') && (
                        <TableCell>
                          {(group.routeType === 'weekly' || group.routeType === 'biweekly') &&
                            group.createdAt.toLocaleDateString()}
                        </TableCell>
                      )}
                      {visibleColumns.has('nextDelivery') && <TableCell>{nextDelivery.toLocaleDateString()}</TableCell>}
                      <TableCell>
                        <IconButton
                          onClick={() => handleOpen(group)}
                          sx={{
                            color: '#00b4d8',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 180, 216, 0.1)',
                            },
                          }}
                        >
                          <EditIcon className="edit-icon" sx={{ color: '#00b4d8', marginRight: '8px' }} />
                          <Typography variant="button" sx={{ color: '#00b4d8' }}>
                            EDITAR RUTA
                          </Typography>
                        </IconButton>
                        <IconButton color="error" onClick={() => group.id && handleDelete(group.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )
      }

      {
        tabValue === 1 && (
          // Vista de Lista de Cartas
          <Grid container spacing={3}>
            {filteredRouteGroups.map((group) => (
              <Grid item xs={12} sm={6} md={4} key={group.id}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent>
                    <Typography variant="h6" component="div" gutterBottom>
                      {group.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Tipo de Ruta: {group.routeType === 'weekly' ? 'Semanal' :
                        group.routeType === 'biweekly' ? 'Bisemanal' :
                          group.routeType === 'monthly' ? 'Mensual' : 'Bimensual'}
                    </Typography>
                    {(group.routeType === 'monthly' || group.routeType === 'bimonthly') && (
                      <>
                        <Typography variant="body2" color="text.secondary">
                          Día de la Semana: {getDayOfWeekName(group.dayOfWeek)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Semana del Mes: {group.weekOfMonth}
                        </Typography>
                      </>
                    )}
                    {(group.routeType === 'weekly' || group.routeType === 'biweekly') && (
                      <Typography variant="body2" color="text.secondary">
                        Fecha de Inicio: {group.createdAt.toLocaleDateString()}
                      </Typography>
                    )}
                    <Typography variant="body2" color="text.secondary">
                      Siguiente Entrega: {calculateNextDeliveryDate(group).toLocaleDateString()}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleOpen(group)}>Editar</Button>
                    <Button size="small" color="error" onClick={() => group.id && handleDelete(group.id)}>Eliminar</Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )
      }

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredRouteGroups.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Filas por página:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        sx={{
          '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
            margin: 0,
          },
          '.MuiTablePagination-toolbar': {
            paddingLeft: 2,
            paddingRight: 2,
          },
        }}
      />


      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle sx={{ backgroundColor: '#87CEEB', color: 'white', fontWeight: 'bold' }}>
          {currentRouteGroup?.id ? 'Editar Grupo de Rutas' : 'Agregar Grupo de Rutas'}
        </DialogTitle>
        <DialogContent dividers>
          <Tabs
            value={0}
            aria-label="route group tabs"
            sx={{
              mb: 2,
              '& .MuiTab-root': {
                color: '#87CEEB',
                '&.Mui-selected': {
                  color: '#5CACEE',
                },
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#5CACEE',
              },
            }}
          >
            <Tab label="Información General" />
          </Tabs>
          <Box sx={{ height: '510px', overflow: 'auto' }}>
            <InfoSection elevation={3}>
              <SectionTitle variant="h6">
                <IconWrapper>
                  <CalendarToday />
                  Información General
                </IconWrapper>
              </SectionTitle>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <StyledFormControl fullWidth>
                    <TextField
                      label="Nombre"
                      value={currentRouteGroup?.name || ''}
                      onChange={(e) => setCurrentRouteGroup(currentRouteGroup ? { ...currentRouteGroup, name: e.target.value } : null)}
                    />
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <StyledFormControl fullWidth>
                    <InputLabel>Tipo de Ruta</InputLabel>
                    <Select
                      value={currentRouteGroup?.routeType || 'weekly'}
                      onChange={(e) => handleRouteTypeChange(e.target.value as 'weekly' | 'biweekly' | 'monthly' | 'bimonthly')}
                    >
                      <MenuItem value="weekly">Semanal</MenuItem>
                      <MenuItem value="biweekly">Bisemanal</MenuItem>
                      <MenuItem value="monthly">Mensual</MenuItem>
                      <MenuItem value="bimonthly">Bimensual</MenuItem>
                    </Select>
                  </StyledFormControl>
                </Grid>
                {(currentRouteGroup?.routeType === 'monthly' || currentRouteGroup?.routeType === 'bimonthly') && (
                  <>
                    <Grid item xs={12} md={3}>
                      <StyledFormControl fullWidth>
                        <InputLabel>Día de la Semana</InputLabel>
                        <Select
                          value={currentRouteGroup?.dayOfWeek || 1}
                          onChange={(e) => setCurrentRouteGroup(currentRouteGroup ? { ...currentRouteGroup, dayOfWeek: e.target.value as number } : null)}
                        >
                          {[1, 2, 3, 4, 5, 6, 7].map((day) => (
                            <MenuItem key={day} value={day}>{getDayOfWeekName(day)}</MenuItem>
                          ))}
                        </Select>
                      </StyledFormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <StyledFormControl fullWidth>
                        <InputLabel>Semana del Mes</InputLabel>
                        <Select
                          value={currentRouteGroup?.weekOfMonth || 1}
                          onChange={(e) => setCurrentRouteGroup(currentRouteGroup ? { ...currentRouteGroup, weekOfMonth: e.target.value as number } : null)}
                        >
                          {[1, 2, 3, 4, 5].map((week) => (
                            <MenuItem key={week} value={week}>{week}</MenuItem>
                          ))}
                        </Select>
                      </StyledFormControl>
                    </Grid>
                  </>
                )}
                {(currentRouteGroup?.routeType === 'weekly' || currentRouteGroup?.routeType === 'biweekly') && (
                  <Grid item xs={12} md={3}>
                    <StyledFormControl fullWidth>
                      <DatePicker
                        selected={currentRouteGroup?.createdAt}
                        onChange={handleCreatedAtChange}
                        customInput={
                          <TextField
                            fullWidth
                            label="Fecha de inicio"
                            InputProps={{
                              startAdornment: (
                                <Today style={{ color: '#00b4d8', marginRight: '8px' }} />
                              ),
                            }}
                          />
                        }
                      />
                    </StyledFormControl>
                  </Grid>
                )}
              </Grid>
            </InfoSection>

            <Grid item xs={12}>
              <Box sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: '12px',
                padding: '24px',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <SectionTitle variant="h6">
                    <IconWrapper>
                      <FormatListBulleted />
                      Lista de Pedidos
                    </IconWrapper>
                  </SectionTitle>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={addRoute}
                    variant="contained"
                    sx={{
                      mt: 2,
                      backgroundColor: '#00b4d8',
                      '&:hover': {
                        backgroundColor: '#0077be',
                      },
                    }}
                  >
                    Agregar Ruta
                  </Button>
                </Box>

                {currentRouteGroup?.routes.map((route, index) => (
                  <Paper
                    key={route.id || `route-${index}`}
                    elevation={1}
                    sx={{
                      mb: 2,
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: 'white',
                      borderLeft: `6px solid ${index === 0 ? '#FFA500' : '#00b4d8'}`,
                      transition: 'all 0.3s ease',
                      '&:hover': { transform: 'translateY(-4px)' }
                    }}
                  >
                    <Avatar sx={{
                      bgcolor: (index === 0 || index === currentRouteGroup.routes.length - 1) ? '#FFA500' : '#00b4d8',
                      color: 'white',
                      mr: 2
                    }}>
                      {index + 1}
                    </Avatar>

                    <Tooltip title={`Tipo de entrega: ${clients.find(c => c.id === route.clientId)?.tipo_entrega || 'No especificado'}`}>
                      <IconButton sx={{ mr: 2 }}>
                        {clients.find(c => c.id === route.clientId)?.tipo_entrega === 'Recolección'
                          ? <RecoleccionIcon sx={{ color: 'green' }} />
                          : <OllinIcon sx={{ color: '#00b4d8' }} />}
                      </IconButton>
                    </Tooltip>

                    <FormControl fullWidth sx={{ mr: 2 }}>
                      <InputLabel>Cliente</InputLabel>
                      <Select
                        value={route.clientId || ''}
                        onChange={(e) => handleRouteChange(index, 'clientId', e.target.value as string)}
                      >
                        <MenuItem value="" disabled>
                          <em>Seleccione un cliente</em>
                        </MenuItem>
                        {clients
                          .filter(client => !currentRouteGroup.routes.some(r => r.clientId === client.id && r !== route))
                          .map((client) => (
                            <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                    <FormControl sx={{ width: '222px', mr: 2 }}>
                      <InputLabel>Estado</InputLabel>
                      <Select
                        value={route.status || 'pending'}
                        onChange={(e) => handleRouteChange(index, 'status', e.target.value as 'pending' | 'completed' | 'cancelled')}
                      >
                        <MenuItem value="pending">
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <PendingActions sx={{ mr: 1, color: '#FFA500' }} />
                            Pendiente
                          </Box>
                        </MenuItem>
                        <MenuItem value="completed">
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <CheckCircle sx={{ mr: 1, color: '#4CAF50' }} />
                            Completado
                          </Box>
                        </MenuItem>
                        <MenuItem value="cancelled">
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Cancel sx={{ mr: 1, color: '#F44336' }} />
                            Cancelado
                          </Box>
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                      variant="outlined"
                      onClick={() => handleCreateOrEditOrder(currentRouteGroup!, route)}
                      sx={{
                        mr: 2,
                        borderColor: '#00b4d8',
                        color: '#00b4d8',
                        '&:hover': {
                          borderColor: '#0077be',
                          backgroundColor: 'rgba(0, 180, 216, 0.1)',
                        },
                      }}
                    >
                      {route.order && route.order.items && route.order.items.length > 0 ? 'Editar Pedido' : 'Crear Pedido'}
                    </Button>
                    <IconButton onClick={() => removeRoute(index)} sx={{ color: '#F44336' }}>
                      <DeleteIcon />
                    </IconButton>
                  </Paper>
                ))}

              </Box>
            </Grid>

          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', px: 3, py: 2, backgroundColor: 'rgba(135, 206, 235, 0.1)' }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              color: '#87CEEB',
              borderColor: '#87CEEB',
              '&:hover': {
                backgroundColor: 'rgba(135, 206, 235, 0.1)',
                borderColor: '#5CACEE',
              },
              fontWeight: 'bold',
              px: 4,
              py: 1,
              mr: 2,
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            disabled={!isFormValid()}
            sx={{
              backgroundColor: '#87CEEB',
              color: 'white',
              '&:hover': {
                backgroundColor: '#5CACEE',
              },
              '&:disabled': {
                backgroundColor: 'rgba(135, 206, 235, 0.5)',
                color: 'rgba(255, 255, 255, 0.7)',
              },
              fontWeight: 'bold',
              px: 4,
              py: 1,
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <OrderCreationModal
        open={isOrderModalOpen}
        onClose={() => setIsOrderModalOpen(false)}
        inventoryItems={inventoryItems}
        existingOrder={currentRoute?.order ?? undefined}
        onOrderCreated={handleOrderCreated}
      />

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <Typography>¿Estás seguro de que quieres eliminar este grupo de rutas?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={filterDialogOpen}
        onClose={handleFilterDialogClose}
        maxWidth="md"
        fullWidth
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        }}
      >
        <DialogTitle sx={{ backgroundColor: '#87CEEB', color: 'white', fontWeight: 'bold' }}>
          Filtrar Grupos de Rutas
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6}>
              {/* Aquí va el resto de tu código, incluyendo el ComboBox */}
              <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                <InputLabel sx={{ textAlign: 'center', width: '100%' }}>Tipo de Ruta</InputLabel>
                <Select
                  multiple
                  value={filters.routeType || []}
                  onChange={(e) => {
                    handleFilterChange('routeType', e.target.value as string[]);
                    setSelectedRouteTypes(e.target.value as string[]); // Actualiza el estado aquí
                  }}
                  input={<OutlinedInput label="Tipo de Ruta" />}
                  renderValue={(selected) => (selected as string[]).map(type => {
                    switch (type) {
                      case 'weekly': return 'Semanal';
                      case 'biweekly': return 'Bisemanal';
                      case 'monthly': return 'Mensual';
                      case 'bimonthly': return 'Bimensual';
                      default: return type;
                    }
                  }).join(', ')} // Traducción al español
                >
                  {['weekly', 'biweekly', 'monthly', 'bimonthly'].map((type) => (
                    <MenuItem key={type} value={type}>
                      <Checkbox checked={(filters.routeType || []).indexOf(type) > -1} />
                      <ListItemText primary={type === 'weekly' ? 'Semanal' :
                        type === 'biweekly' ? 'Bisemanal' :
                          type === 'monthly' ? 'Mensual' : 'Bimensual'} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{
          justifyContent: 'center',
          px: 3,
          py: 2,
          backgroundColor: 'rgba(135, 206, 235, 0.1)'
        }}>
          <Button
            onClick={handleFilterDialogClose}
            variant="outlined"
            sx={{
              color: '#87CEEB',
              borderColor: '#87CEEB',
              '&:hover': {
                backgroundColor: 'rgba(135, 206, 235, 0.1)',
                borderColor: '#5CACEE',
              },
              fontWeight: 'bold',
              px: 4,
              py: 1,
              mr: 2,
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={applyFilters}
            variant="contained"
            sx={{
              backgroundColor: '#87CEEB',
              color: 'white',
              '&:hover': {
                backgroundColor: '#5CACEE',
              },
              fontWeight: 'bold',
              px: 4,
              py: 1,
            }}
          >
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={columnSelectionDialogOpen}
        onClose={handleColumnSelectionDialogClose}
        PaperProps={{
          sx: {
            width: '400px',
            maxHeight: '80vh',
          }
        }}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        }}
      >
        <DialogTitle sx={{
          backgroundColor: '#87CEEB',
          color: 'white',
          fontWeight: 'bold'
        }}>
          Seleccionar Columnas
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 2
          }}>
            {['name', 'routeType', 'createdAt', 'dayOfWeek', 'weekOfMonth', 'nextDelivery'].map((column) => (
              <FormControlLabel
                key={column}
                control={
                  <Checkbox
                    checked={visibleColumns.has(column)}
                    onChange={() => handleColumnToggle(column)}
                    sx={{
                      color: '#87CEEB',
                      '&.Mui-checked': {
                        color: '#87CEEB',
                      },
                    }}
                  />
                }
                label={columnNames[column as keyof typeof columnNames] || column}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': {
                    backgroundColor: 'rgba(135, 206, 235, 0.08)',
                    borderRadius: '4px',
                  },
                }}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', px: 3, py: 2, backgroundColor: 'rgba(135, 206, 235, 0.1)' }}>
          <Button
            onClick={handleColumnSelectionDialogClose}
            variant="outlined"
            sx={{
              color: '#87CEEB',
              borderColor: '#87CEEB',
              '&:hover': {
                backgroundColor: 'rgba(135, 206, 235, 0.1)',
                borderColor: '#5CACEE',
              },
              fontWeight: 'bold',
              px: 4,
              py: 1,
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box >
  );
};

export default RouteGroupsCRUD;